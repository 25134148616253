export function getRentalShippingDateRange(
  eventDate: number,
  rentalLeadTimeMin: number = 5,
  rentalLeadTimeMax: number = 10
) {
  let minDateOffset = (24*60*60*1000) * rentalLeadTimeMin;
    let maxDateOffset = (24*60*60*1000) * rentalLeadTimeMax;
    let eventTimestamp = new Date(eventDate * 1000);
    let minDate = new Date(eventTimestamp.getTime() - minDateOffset);
    let maxDate = new Date(eventTimestamp.getTime() - maxDateOffset);
    /*
    let maxDatePretty = maxDate.getUTCMonth() > 8 ? (maxDate.getUTCMonth() + 1).toString() : '0' + (maxDate.getUTCMonth() + 1).toString();
    maxDatePretty += '/';
    maxDatePretty += (maxDate.getUTCDate() > 9) ? maxDate.getUTCDate().toString() : '0' + maxDate.getUTCDate().toString();
    maxDatePretty += '/';
    maxDatePretty += (maxDate.getUTCFullYear() - 2000).toString(); // couldn't substring it for some reason.
    let minDatePretty = minDate.getUTCMonth() > 8 ? (minDate.getUTCMonth() + 1).toString() : '0' + (minDate.getUTCMonth() + 1).toString();
    minDatePretty += '/';
    minDatePretty += (minDate.getUTCDate() > 9) ? minDate.getUTCDate().toString() : '0' + minDate.getUTCDate().toString();
    minDatePretty += '/';
    minDatePretty += (minDate.getUTCFullYear() - 2000).toString(); // couldn't substring it for some reason.


    return [
      maxDatePretty,
      minDatePretty
    ]
    */
   return [
    maxDate.getTime(),
    minDate.getTime()
   ]
}


