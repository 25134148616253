import * as z from 'zod';

export const PhoneStringSchema = z.string({ invalid_type_error: "Please enter a valid phone number" })
  .trim()
  // .regex(/^\d{10}$/, "Please enter a valid phone number") // must be 10 digits
  .refine((phone) => {
    const phonePattern = /^\d{10}$/; // must be 10 digits
    const isMatch = phone.match(phonePattern);
    return phone ? isMatch : true // phone number must be 10 digits if it is not empty or null
  }, "Please enter a valid phone number")
  .nullable();

export type PhoneString = z.infer<typeof PhoneStringSchema>;
