import { Order, CommissionDetail } from 'common-types';
import { getCommissionDetails } from './get-commission-details';

export function getRentalCommission(order: Order): number {
  let commissionDetails: Array<CommissionDetail> = getCommissionDetails(order);
  return commissionDetails.reduce((accumulator, currentValue) => {
    if (currentValue.styleGroup === 'Rental') {
      return accumulator + (currentValue.commission ?? 0);
    } else {
      return accumulator + 0;
    }
  }, 0);
}
