type Product = {
  displayName: string;
  segmentCode: string;
  legacyProdId: string;
};

export const StaticProducts: Array<Product> = [
  {
    displayName: 'Coat',
    segmentCode: 'CT',
    legacyProdId: 'CT',
  },
  {
    displayName: 'Pant',
    segmentCode: 'PT',
    legacyProdId: 'PT',
  },
  {
    displayName: 'Shirt',
    segmentCode: 'SR',
    legacyProdId: 'SR',
  },
  {
    displayName: 'Tie',
    segmentCode: 'TI',
    legacyProdId: 'TI',
  },
  {
    displayName: 'Vest',
    segmentCode: 'VS',
    legacyProdId: 'VS',
  },
  {
    displayName: 'PocketSquare',
    segmentCode: 'PK',
    legacyProdId: 'PK',
  },
  {
    displayName: 'Shoes',
    segmentCode: 'SO',
    legacyProdId: 'SO',
  },
  {
    displayName: 'Socks',
    segmentCode: 'HS',
    legacyProdId: 'HS',
  },
  {
    displayName: 'Jewelry',
    segmentCode: 'JW',
    legacyProdId: 'JW',
  },
  {
    displayName: 'Suspenders',
    segmentCode: 'SU',
    legacyProdId: 'SU',
  },
  {
    displayName: 'Miscellaneous',
    segmentCode: 'MS',
    legacyProdId: 'MS',
  },
  {
    displayName: 'Arm Bands',
    segmentCode: 'AB',
    legacyProdId: 'AB',
  },
];
