import { EcomStyle, Event } from 'common-types';

export function isEcomStyleDiscontinued(ecomStyle: EcomStyle): boolean {
  if (
    ecomStyle.discontinuedDate === undefined &&
    ecomStyle.webDiscontinuedDate === undefined
  ) {
    return false;
  }

  const currentDate = Math.floor(new Date().getTime() / 1000);
  //console.log('currentDate:' + currentDate);

  // const currentDate = Date.now();
  // console.log('currentDate:' + currentDate);

  const discontinuedDate = ecomStyle.discontinuedDate;
  //console.log('discontinuedDate:' + discontinuedDate);

  const webDiscontinuedDate = ecomStyle.webDiscontinuedDate;
  //console.log('webDiscontinuedDate:' + webDiscontinuedDate);

  if (
    (discontinuedDate !== undefined && discontinuedDate < currentDate) ||
    (webDiscontinuedDate !== undefined && webDiscontinuedDate < currentDate)
  ) {
    return true;
  }

  return false;
}
