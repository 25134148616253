export function getOrderingMembersSessionStorage() {
  const membersProcessingOrder = sessionStorage.getItem(
    'membersProcessingOrder'
  );
  if (membersProcessingOrder) {
    return JSON.parse(membersProcessingOrder);
  } else {
    return [];
  }
}
