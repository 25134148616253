import { Event, EventMember } from 'common-types';

/**
 * Checks if a member is an admin for an event by comparing the member's email to the emails of the event's admins.
 * Since member id's and admin id's are not connected to Auth, we can only compare emails.
 * For example, if three members have the same email address, they will all be considered admins for the event
 * because Auth is tied to the email address.
 * This also helps prohibit adding multiple members with the same email address to the admins array.
 * @param member
 * @param event
 * @returns
 */
export function isMemberAdminForEvent(
  member: EventMember | undefined,
  event: Event | undefined
): boolean {

  if (!member || !event) {
    return false;
  }
  // const organizer = getOrganizerFromEvent(event);
  // const isOrganizer = organizer?.email?.toLowerCase() === member.email?.toLowerCase();

  return event.admins.some((admin) => admin.email.toLowerCase() === member.email?.toLowerCase());

}
