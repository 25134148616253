import {
  CanBeValidated,
  DealerAccountContact,
  DealerAccount as DealerAccountInterface,
  Serializable,
} from 'common-types';
import { ZodError, ZodSchema } from 'zod';
import { DealerAccountValidator } from './validation/dealer-account-validator';

export class DealerAccount
  implements
    Partial<DealerAccountInterface>,
    CanBeValidated,
    Serializable<DealerAccount>
{
  lat?: number;
  lon?: number;
  address1?: string;
  address2?: string;
  city?: string;
  phone?: string;
  aname?: string;
  email?: string;
  state?: string;
  actnum?: string;
  isActive?: boolean;
  fri_to?: string;
  mon_to?: string;
  sat_to?: string;
  strtyp?: string;
  sun_to?: string;
  thu_to?: string;
  tue_to?: string;
  wed_to?: string;
  zipCode?: string;
  website?: string;
  fri_from?: string;
  fri_type?: string;
  mon_from?: string;
  mon_type?: string;
  sat_from?: string;
  sat_type?: string;
  sun_from?: string;
  sun_type?: string;
  thu_from?: string;
  thu_type?: string;
  tue_from?: string;
  tue_type?: string;
  wed_from?: string;
  wed_type?: string;
  storeDescription?: string;
  warehouse?: string;
  landingPageUrl?: string;
  contacts?: Array<DealerAccountContact>;

  toObject() {
    return {
      lat: this.lat,
      lon: this.lon,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      phone: this.phone,
      aname: this.aname,
      email: this.email,
      state: this.state,
      actnum: this.actnum,
      isActive: this.isActive,
      fri_to: this.fri_to,
      mon_to: this.mon_to,
      sat_to: this.sat_to,
      strtyp: this.strtyp,
      sun_to: this.sun_to,
      thu_to: this.thu_to,
      tue_to: this.tue_to,
      wed_to: this.wed_to,
      zipCode: this.zipCode,
      website: this.website,
      fri_from: this.fri_from,
      fri_type: this.fri_type,
      mon_from: this.mon_from,
      mon_type: this.mon_type,
      sat_from: this.sat_from,
      sat_type: this.sat_type,
      sun_from: this.sun_from,
      sun_type: this.sun_type,
      thu_from: this.thu_from,
      thu_type: this.thu_type,
      tue_from: this.tue_from,
      tue_type: this.tue_type,
      wed_from: this.wed_from,
      wed_type: this.wed_type,
      storeDescription: this.storeDescription,
      warehouse: this.warehouse,
      landingPageUrl: this.landingPageUrl,
      contacts: this.contacts,
    };
  }

  serialize(): string {
    return JSON.stringify(this.toObject());
  }

  deserialize(input: Partial<DealerAccountInterface>): DealerAccount {
    this.lat = input.lat;
    this.lon = input.lon;
    this.address1 = input.address1;
    this.address2 = input.address2;
    this.city = input.city;
    this.phone = input.phone;
    this.aname = input.aname;
    this.email = input.email;
    this.state = input.state;
    this.actnum = input.actnum;
    this.isActive = input.isActive;
    this.fri_to = input.fri_to;
    this.mon_to = input.mon_to;
    this.sat_to = input.sat_to;
    this.strtyp = input.strtyp;
    this.sun_to = input.sun_to;
    this.thu_to = input.thu_to;
    this.tue_to = input.tue_to;
    this.wed_to = input.wed_to;
    this.zipCode = input.zipCode;
    this.website = input.website;
    this.fri_from = input.fri_from;
    this.fri_type = input.fri_type;
    this.mon_from = input.mon_from;
    this.mon_type = input.mon_type;
    this.sat_from = input.sat_from;
    this.sat_type = input.sat_type;
    this.sun_from = input.sun_from;
    this.sun_type = input.sun_type;
    this.thu_from = input.thu_from;
    this.thu_type = input.thu_type;
    this.tue_from = input.tue_from;
    this.tue_type = input.tue_type;
    this.wed_from = input.wed_from;
    this.wed_type = input.wed_type;
    this.storeDescription = input.storeDescription;
    this.warehouse = input.warehouse;
    this.landingPageUrl = input.landingPageUrl;
    this.contacts = input.contacts;

    return this;
  }

  isValid(validationSchema?: ZodSchema): boolean {
    if (validationSchema === undefined) {
      validationSchema = DealerAccountValidator;
    }

    try {
      validationSchema.parse(this);
      return true;
    } catch (err) {
      if (err instanceof ZodError) {
        return false;
      } else {
        throw err;
      }
    }
  }

  getErrors(validationSchema?: ZodSchema): ZodError<any> | undefined {
    if (validationSchema === undefined) {
      validationSchema = DealerAccountValidator;
    }

    try {
      validationSchema.parse(this);
    } catch (err) {
      if (err instanceof ZodError) {
        return err;
      } else {
        throw err;
      }
    }
    return undefined;
  }
}
