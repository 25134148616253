import { EcomStyle, StyleType, StyleGroup } from 'common-types';

export function orderEcomStyles(unorderedStyles: EcomStyle[]): EcomStyle[] {
  const orderedStyles: EcomStyle[] = [
    {
      styleType: StyleType.Coat,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Pant,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Shirt,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Tie,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Vest,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Shoes,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.PocketSquare,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Socks,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Suspenders,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Miscellaneous,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
    {
      styleType: StyleType.Jewelry,
      styleGroup: StyleGroup.Unassigned,
      styleCode: '',
    },
  ];

  for (let i = orderedStyles.length - 1; i >= 0; i--) {
    const orderedStyle = orderedStyles[i];

    const foundStyle = unorderedStyles.find(
      (unorderedStyle) => unorderedStyle.styleType === orderedStyle.styleType
    );

    if (foundStyle) {
      orderedStyles[i] = foundStyle;
    } else {
      orderedStyles.splice(i, 1);
    }
  }

  return orderedStyles;
}
