import { CommissionTypeEnum, Event } from 'common-types';

export function getIndividualInStoreViewCustomLinkSteps(event: Event): boolean {
  if (
    event.isSingleUser === true &&
    event.inStoreInfo &&
    event.commissionType === CommissionTypeEnum.CustomLink
  ) {
    return true;
  }
  return false;
}
