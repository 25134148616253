import { z } from 'zod';

export const EventTypeSchema = z.enum([
  'Wedding',
  'Prom',
  'Quinceañera',
  'Special Event',
]);

export type EventType = z.infer<typeof EventTypeSchema>;

export const EventType = {
  Wedding: EventTypeSchema.enum.Wedding,
  Prom: EventTypeSchema.enum.Prom,
  Quinceañera: EventTypeSchema.enum.Quinceañera,
  SpecialEvent: EventTypeSchema.enum['Special Event']
} as const;

export const EventTypeOptions = EventTypeSchema.options;
