import { z } from "zod";
import { UserEvent, UserEventSchema } from "../../../event/user-event.type";

// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const EventNotFoundResponse = "Event not found" as const;
export const UserEventNotFoundResponse = "UserEvent not found" as const;
export const InvalidUserOrEventResponse = "Invalid user/event" as const;
export const InvalidDataResponse = "Invalid data" as const;
export const UnknownErrorResponse = "Unknown error" as const;
export type UnknownErrorResponseType = typeof UnknownErrorResponse;
export type NonSpecificErrorResponse = any;



// ********************************************************************************************************************
// *                                             CREATE                                                               *
// ********************************************************************************************************************





// ********************************************************************************************************************
// *                                             READ                                                                 *
// ********************************************************************************************************************
export type GetUserEventsForCurrentUserSuccessResponse = UserEvent[];
export const GetUserEventsForCurrentUserFailureResponses = [
  UnknownErrorResponse
];
export type GetUserEventsForCurrentUserFailureResponseType = typeof GetUserEventsForCurrentUserFailureResponses[number];
export type GetUserEventsForCurrentUserResponse = GetUserEventsForCurrentUserSuccessResponse | GetUserEventsForCurrentUserFailureResponseType;


export type GetUserEventsForCurrentUserByEventIdSuccessResponse = UserEvent[];
export const GetUserEventForCurrentUserByEventIdFailureResponses = [
  UnknownErrorResponse
];
export type GetUserEventsForCurrentUserByEventIdFailureResponseType = typeof GetUserEventForCurrentUserByEventIdFailureResponses[number];
export type GetUserEventsForCurrentUserByEventIdResponse = GetUserEventsForCurrentUserByEventIdSuccessResponse | GetUserEventsForCurrentUserByEventIdFailureResponseType;


// COMMENTED OUT THE ROUTE FOR THIS FUNCTION BECAUSE IT IS NOT CURRENTLY USED.
// export type GetUserEventsByEventIdSuccessResponse = UserEvent[];
// export const GetUserEventsByEventIdFailureResponses = [
//   UnknownErrorResponse
// ];
// export type GetUserEventsByEventIdFailureResponseType = typeof GetUserEventsByEventIdFailureResponses[number];
// export type GetUserEventsByEventIdResponse = GetUserEventsByEventIdSuccessResponse | GetUserEventsByEventIdFailureResponseType;


export type GetUserEventByIdSuccessResponse = UserEvent;
export const GetUserEventByIdFailureResponses = [
  UserEventNotFoundResponse,
  InvalidUserOrEventResponse,
  UnknownErrorResponse
];
export type GetUserEventByIdFailureResponseType = typeof GetUserEventByIdFailureResponses[number];
export type GetUserEventByIdResponse = GetUserEventByIdSuccessResponse | GetUserEventByIdFailureResponseType;


// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************

export const UpdateUserEventSchema = UserEventSchema.partial();
export type UpdateUserEventData = z.infer<typeof UpdateUserEventSchema>;
export type UpdateUserEventSuccessResponse = void;
export const UpdateUserEventGenericErrorResponse = "Error updating UserEvent";
export const UpdateUserEventFailureResponses = [
  UserEventNotFoundResponse,
  InvalidDataResponse,
  UnknownErrorResponse,
  UpdateUserEventGenericErrorResponse
];
export type UpdateUserEventFailureResponseType = typeof UpdateUserEventFailureResponses[number];
export type UpdateUserEventResponse = UpdateUserEventSuccessResponse | UpdateUserEventFailureResponseType;


export const SetIsOrganizerSchema = z.object({ isOrganizer: z.boolean() });
export type SetIsOrganizerEventData = z.infer<typeof SetIsOrganizerSchema>;
export type SetIsOrganizerSuccessResponse = void;
export const SetIsOrganizerGenericErrorResponse = "Error updating UserEvent";
export const SetIsOrganizerFailureResponses = [
  UserEventNotFoundResponse,
  InvalidDataResponse,
  UnknownErrorResponse,
  SetIsOrganizerGenericErrorResponse
];
export type SetIsOrganizerFailureResponseType = typeof SetIsOrganizerFailureResponses[number];
export type SetIsOrganizerResponse = SetIsOrganizerSuccessResponse | SetIsOrganizerFailureResponseType;


export type AddMemberIdToAllUserEventsSuccessResponse = { numUserEventDocsUpdated: number };
export const AddMemberIdToAllUserEventsFailureResponses = [
  EventNotFoundResponse,
  UserEventNotFoundResponse,
  InvalidUserOrEventResponse,
  UnknownErrorResponse
];
export type AddMemberIdToAllUserEventsFailureResponseType = typeof AddMemberIdToAllUserEventsFailureResponses[number];
export type AddMemberIdToAllUserEventsResponse = AddMemberIdToAllUserEventsSuccessResponse | AddMemberIdToAllUserEventsFailureResponseType;




// ********************************************************************************************************************
// *                                             DELETE                                                               *
// ********************************************************************************************************************

export type DeleteAllUserEventsByEventIdSuccessResponse = { numUserEventDocsDeleted: number };
export const UserEventHasNoUserIdResponse = "UserEvent has no userId" as const;
export const EventHasCartsResponse = "Event has carts.  No UserEvents were deleted." as const;
// export const UserEventCartMemberHasNoEmailResponse = "A UserEvent cart member has no email" as const;
// export const AuthDoesNotExistForCartMemberResponse = "Auth user does not exist for cart member" as const;
// export const UserEventUserIsInACartResponse = 'User for a UserEvent is in a cart. No UserEvents deleted.' as const;
export const DeleteAllUserEventsByEventIdFailureResponses = [
  EventNotFoundResponse,
  UserEventNotFoundResponse,
  InvalidUserOrEventResponse,
  UserEventHasNoUserIdResponse,
  EventHasCartsResponse,
  UnknownErrorResponse
];
export type DeleteAllUserEventsByEventIdResponse = DeleteAllUserEventsByEventIdSuccessResponse | NonSpecificErrorResponse;

// export type DeleteUserEventByMemberIdEventIdSuccessResponse = { numUserEventDocsDeleted: number };
// export const UserEventNotFoundWithMemberIdResponse = "ALERT UserEvent not found with provided memberId" as const; // This is not wired up in GCP to see this alert message yet.
// export const UserEventIsAdminResponse = "UserEvent is associated with an admin for this event" as const; // This is not wired up in GCP to see this alert message yet.
// export const DeleteUserEventByMemberIdEventIdFailureResponses = [
//   EventNotFoundResponse,
//   UserEventNotFoundResponse,
//   UserEventNotFoundWithMemberIdResponse,
//   InvalidUserOrEventResponse,
//   UserEventUserIsInACartResponse,
//   UnknownErrorResponse
// ]
// export type DeleteUserEventByMemberIdEventIdResponse = DeleteUserEventByMemberIdEventIdSuccessResponse | NonSpecificErrorResponse;

