import { z } from 'zod';
import { dealerAccountCommunicationValidator } from './dealer-account-communication.validator';

export const dealerAccountContactValidator = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  role: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
  emailCommunication: dealerAccountCommunicationValidator.optional(),
  phoneCommunication: dealerAccountCommunicationValidator.optional(),
});
