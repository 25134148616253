import { DealerAccountElastic } from 'common-types';
import { titleCase } from './title-case';

// Potentially retire this if the data comes in already parsed.

// export function titleCaseStoreDetails(stores: Array<Store>): Array<Store> {
//   return stores.map((store) => {
//     store.adr1 = titleCase(store.adr1);
//     store.adr2 = titleCase(store.adr2);
//     store.state = titleCase(store.state);
//     store.city = titleCase(store.city);
//     return store;
//   });
// }

export function titleCaseStoreDetails(
  stores: Array<DealerAccountElastic>
): Array<DealerAccountElastic> {
  return stores.map((store) => {
    store.address1 = titleCase(store.address1);
    store.address2 = titleCase(store.address2);
    store.state = titleCase(store.state);
    store.city = titleCase(store.city);
    return store;
  });
}
