import { DealerAccount } from '../store/dealer-account.type';

export abstract class DealerAccountRepository {
  abstract getAllDealerAccounts(): Promise<DealerAccount[]>;
  abstract getDealerAccountById(id: string): Promise<DealerAccount | undefined>;
  abstract getDealerAccountByActnum(
    actnum: string
  ): Promise<DealerAccount | undefined>;
  abstract addDealerAccount(DealerAccount: DealerAccount): Promise<string>;
  abstract deleteDealerAccountById(id: string): Promise<any>;
  abstract updateDealerAccountById(
    id: string,
    DealerAccount: DealerAccount
  ): any;
}
