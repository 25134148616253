import { EventRole } from 'common-types';
import { StaticEventRoles } from '../../event-member/utilities/static-event-roles';

export function getLegacyEventRole(memberRole: string): string {
  let index = StaticEventRoles.findIndex((eventRole: EventRole) => {
    return eventRole.name === memberRole;
  });
  if (index > -1) {
    // found it.
    return StaticEventRoles[index].legacyCode;
  } else {
    return 'OT'; // return Other role if not found.
  }
}
