import * as z from 'zod';

export const CreatedBySchema = z.object({
  userId: z.string(),
  email: z.string(),
  name: z.string(),
});

export type ZodCreatedBy = z.infer<typeof CreatedBySchema>;

export type CreatedBy = {
  userId: string;
  email: string;
  name: string;
};
