import {
  convertDateToLegacyDate,
  convertUnixTimestampToDate,
} from '../../../date';

export function getLegacyProductionWeek(eventDate: number): string {
  let date: Date = convertUnixTimestampToDate(eventDate);
  switch (date.getDay()) {
    case 0: // Sunday
      date.setDate(date.getDate() - 1);
      return convertDateToLegacyDate(date);
    case 1: // Monday
      date.setDate(date.getDate() + 5);
      return convertDateToLegacyDate(date);
    case 2: // Tuesday
      date.setDate(date.getDate() + 4);
      return convertDateToLegacyDate(date);
    case 3: // Wednesday
      date.setDate(date.getDate() + 3);
      return convertDateToLegacyDate(date);
    case 4: // Thursday
      date.setDate(date.getDate() + 2);
      return convertDateToLegacyDate(date);
    case 5: // Friday
      date.setDate(date.getDate() + 1);
      return convertDateToLegacyDate(date);
    default: // Saturday
      return convertDateToLegacyDate(date);
  }
}
