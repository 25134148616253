import { Measurements } from 'common-types';

function cmToInches(cm: any): number | undefined {
  return Number((cm / 2.54).toFixed(2));
}

function cmToFeetAndInchesString(cm: number): string {
  const inches = cm / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);
  return `${feet}'${remainingInches}"`;
}

function kilogramsToPounds(kilograms: number): number {
  const pounds = kilograms * 2.20462;
  return pounds;
}
export const getMeasurementsFromWidget = (
  measurementData: any
): Measurements => {
  var mapped: Measurements = {
    derivedFrom: 'tool',
    chest: cmToInches(measurementData.volume_params.chest),
    overarm: cmToInches(measurementData.volume_params.overarm_girth),
    // Waist = hip - 6inches
    waist: cmToInches(measurementData.volume_params.low_hips)! - 6,
    hip: cmToInches(measurementData.volume_params.low_hips),
    stomach: cmToInches(measurementData.volume_params.abdomen),
    neck: cmToInches(measurementData.volume_params.neck),
    sleeve: cmToInches(
      measurementData.front_params.back_neck_point_to_wrist_length_1_5_inch
    ),
    // insleeve: 1,
    outseam:
      cmToInches(measurementData.front_params.outseam_from_upper_hip_level)! +
      1.5,
    // inseam: 1,
    height: cmToFeetAndInchesString(measurementData.height),
    weight: kilogramsToPounds(measurementData.weight),
    // coatSize: "",
    // vestSize: "",
    // shoeSize: ""
  };

  return mapped;
};
