import { EcomEnsemble, Look } from 'common-types';

export function getLookFromEnsemble(ensemble: EcomEnsemble): Look {
  const look: Look = {
    id: ensemble.id,
    title: ensemble.title,
    styles: ensemble.styles,
    price: ensemble.price,
    ensembleCode: ensemble.ensembleCode,
  };

  return look;
}
