import * as z from 'zod';

/**
 * Password requirements for new passwords:
 * -- Must include at least one uppercase character.
 * -- Must include at least one lowercase character.
 * -- Must include at least one number.
 * -- Must include at least one non-alphanumeric character.
 * -- Must be at least 10 characters.
 */
export const JFWNewPasswordSchema = z.string({ invalid_type_error: 'Password is required' }) // catch invalid type error like null
  .trim()
  .superRefine((newPassword, ctx) => {
    if (!newPassword) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: true, message: 'Password is required' })
    }
    // Check if the password contains at least one uppercase character.
    if (!/[A-Z]/.test(newPassword)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: false, message: 'Password must contain at least one uppercase character' })
    }

    // Check if the password contains at least one lowercase character.
    if (!/[a-z]/.test(newPassword)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: false, message: 'Password must contain at least one lowercase character' })
    }

    // Check if the password contains at least one numeric character
    if (!/[0-9]/.test(newPassword)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: false, message: 'Password must contain at least one number' })
    }

    // Check if the password contains at least one non-alphanumeric character.
    if (!/[^a-zA-Z0-9]/.test(newPassword)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: false, message: 'Password must contain at least one non-alphanumeric character' })
    }

    // Check if the password length is at least 10 characters.
    if (newPassword.length < 10) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: true, message: 'Password must be at least 10 characters' });
    }
  });

/**
 * Password requirements for new passwords:
 * -- Must include at least one uppercase character.
 * -- Must include at least one lowercase character.
 * -- Must include at least one number.
 * -- Must include at least one non-alphanumeric character.
 * -- Must be at least 10 characters.
 */
export type JFWPassword = z.infer<typeof JFWNewPasswordSchema>;
