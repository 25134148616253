import { Event } from './event';

export class EventFactory {
  static loadEventFromFirebase(jsonEventObject: string): Event {
    let event = new Event();
    console.log(jsonEventObject);
    return event;
  }

  static loadEventFromDb2(eventRecord: Array<string>): Event {
    let event = new Event();
    console.log(eventRecord);
    return event;
  }
}
