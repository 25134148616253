import { EcomStyle } from 'common-types';

export function isResaleWarehouse(style: EcomStyle): boolean {
  return (
    style.warehouses !== undefined &&
    style.warehouses.length === 1 &&
    style.warehouses[0] !== undefined &&
    style.warehouses[0] === 'Ottawa'
  );
}
