import { CreatedBy, CreatedBySchema } from './created-by.type';
import { TransferredBy, TransferredBySchema } from './transferred-by.type';
import * as z from 'zod';

export const DealerInfoSchema = z.object({
  dealerId: z.string(),
  transferredByDealer: TransferredBySchema.optional(),
  createdByDealer: CreatedBySchema,
});

export type ZodDealerInfo = z.infer<typeof DealerInfoSchema>;

export type DealerInfo = {
  dealerId: string;
  transferredByDealer?: TransferredBy;
  createdByDealer: CreatedBy;
};
