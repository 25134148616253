// api is currently in api-internal/event/src/inviteV3/inviteV3.ts (but is deployed to Ecom Firebase project)

import z from 'zod';
import { InviteStatus } from '../../..//landing-page';

// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const UnauthorizedUserResponse = "Unauthorized" as const;
export type UnauthorizedUserResponseType = typeof UnauthorizedUserResponse;
export const InvalidDataResponse = "invalid data" as const;
export type InvalidDataResponseType = typeof InvalidDataResponse;
export const MissingDataResponse = "missing data" as const;
export type MissingDataResponseType = typeof MissingDataResponse;
export const TransactionError = "transaction error" as const; // not used for a response, just for logging
export const NonSpecificErrorResponse = 'unknown error' as const;
export type NonSpecificErrorResponseType = typeof NonSpecificErrorResponse;


export const VerifyEventInviteDataSchema = z.object({
  eventId: z.string(),
  emailAddress: z.string()
});

export type VerifyEventInviteData = z.infer<typeof VerifyEventInviteDataSchema>;

export type VerifyEventInviteSuccessResponse = InviteStatus;  // These are both sent with a 200 status code
export type VerifyEventInviteErrorResponse = UnauthorizedUserResponseType
  | InvalidDataResponseType
  | MissingDataResponseType
  | NonSpecificErrorResponseType;
export type VerifyEventInviteResponse = VerifyEventInviteSuccessResponse | VerifyEventInviteErrorResponse;

