import { PhoneObjectSchema } from "./phone-object.type";
import { PhoneStringSchema } from "./phone-string.type";
import * as z from "zod";

// This converts an object with phone as a property into just a string.
// Currently the phone-form component returns an object with phone as a nested property.
// This makes it easier on the backend to just have a string value for phone instead of an object that needs to be unpacked.
export const PhoneToStringSchema = PhoneObjectSchema.transform((obj) => obj?.phone ?? null).pipe(PhoneStringSchema);

export type PhoneToString = z.infer<typeof PhoneToStringSchema>;


