export enum CartProcessorStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  // PAYMENT_SAVED = 'PAYMENT_SAVED',
  // TAXES_SAVED = 'TAXES_SAVED',
  // MEMBER_ADDRESSES_SAVED = 'MEMBER_ADDRESSES_SAVED',
  // USEREVENT_UPDATES_APPLIED = 'USEREVENT_UPDATES_APPLIED',
  // NOTIFICATIONS_SENT = 'NOTIFICATIONS_SENT',
}
