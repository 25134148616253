import {
  Cart,
  TaxCategory,
  Transaction,
  TransactionCategory,
} from 'common-types';
import { getCurrentUnixTimestamp } from '../date';
import { getMemberIdsFromCart } from '../cart';

export function convertCartToTransaction(cart: Cart): Transaction {
  let memberIds = getMemberIdsFromCart(cart);
  let transaction: Transaction = {
    timestamp: getCurrentUnixTimestamp(),
    cartId: cart.id,
    eventId: cart.eventId,
    orderIds: [],
    memberIds: memberIds,
    elavonTransactionId: cart.paymentChargeResponse?.transactionId ?? '',
    elavonReferenceId: cart.paymentChargeResponse?.referenceId ?? '',
    transactionCategory: TransactionCategory.Initial,
    cardHolder:
      cart.paymentChargeResponse?.elavonResponse.ssl_first_name ??
      '' + ' ' + cart.paymentChargeResponse?.elavonResponse.ssl_last_name ??
      '',
    cardNumber:
      cart.paymentChargeResponse?.elavonResponse.ssl_card_number ?? '',
    userId: cart.userId ?? '',
    members: cart.members ?? [],
    orders: [],
    amount: {
      amount: cart.subtotal,
      tax: cart.taxes,
      taxCategory: TaxCategory.Total,
    },
  };
  return transaction;
}
