import { z, ZodSchema } from 'zod';
import { IdValidator, ImageValidator } from '../../common';
import { AttributeValidator } from './attribute-validator';

export const ProductValidator: ZodSchema = z.object({
  id: IdValidator,
  title: z.string().max(150),
  type: z.string().max(150),
  categories: z.string().array(),
  transactionType: z.string().max(25),
  images: ImageValidator.array(),
  mannequinImages: ImageValidator.array(),
  attributes: AttributeValidator.array(),
  designer: z.string().max(150),
  description: z.string().max(150),
  priceRent: z.number().max(999.99),
  priceBuy: z.number().max(9999.99),
});
