import {
  Attachments,
  CoatTypes,
  CollarTypes,
  Collections,
  Colors,
  Designers,
  EcomStyle,
  Fabrics,
  Finishes,
  Fits,
  JewelryTypes,
  Lapels,
  Legs,
  Materials,
  MeasurementScales,
  MiscellaneousTypes,
  Patterns,
  PlatformStyle,
  Pockets,
  Programs,
  ShirtFronts,
  ShoeTypes,
  SizeScales,
  SockPatterns,
  Supplies,
  TieTypes,
  Vents,
  VestBacks,
  VestTypes,
  Waists,
  Warehouses,
} from 'common-types';

export function getEcomStyle(style: PlatformStyle): EcomStyle {
  const ecomStyle: EcomStyle = {
    activeDate: style.activeDate,
    attachment: new Attachments().getDisplayName(style.attachment),
    beltLoops: style.beltLoops,
    buttons: style.buttons,
    categories: style.categories,
    coatLength: style.coatLength,
    coatType: new CoatTypes().getDisplayName(style.coatType),
    collarType: new CollarTypes().getDisplayName(style.collarType),
    collection: new Collections().getDisplayName(style.collection),
    colors: new Colors().getDisplayNames(style.colors),
    colorShadeId: style.colorShadeId,
    colorShade: style.colorShade,
    colorHexCode: style.colorHexCode,
    colorFamily: style.colorFamily,
    companionStyleCode: style.companionStyleCode,
    companionStylePrice: style.companionStylePrice,
    designer: new Designers().getDisplayName(style.designer),
    discontinuedDate: style.discontinuedDate,
    fabric: new Fabrics().getDisplayName(style.fabric),
    finish: new Finishes().getDisplayName(style.finish),
    fit: new Fits().getDisplayName(style.fit),
    images: style.images,
    jewelryType: new JewelryTypes().getDisplayName(style.jewelryType),
    keywords: style.keywords,
    lapel: new Lapels().getDisplayName(style.lapel),
    leg: new Legs().getDisplayName(style.leg),
    legacyStyleCode: style.legacyStyleCode,
    mannequinImages: style.mannequinImages,
    marketingDescription: style.marketingDescription,
    marketingTitle: style.marketingTitle,
    material: new Materials().getDisplayName(style.material),
    measurementScale1: new MeasurementScales().getDisplayName(
      style.measurementScale1
    ),
    measurementScale2: new MeasurementScales().getDisplayName(
      style.measurementScale2
    ),
    miscellaneousType: new MiscellaneousTypes().getDisplayName(
      style.miscellaneousType
    ),
    pattern: new Patterns().getDisplayName(style.pattern),
    pocket: new Pockets().getDisplayName(style.pocket),
    price: style.webPrice,
    program: new Programs().getDisplayName(style.program),
    recommendedStyles: style.recommendedStyles,
    shirtFront: new ShirtFronts().getDisplayName(style.shirtFront),
    shoeType: new ShoeTypes().getDisplayName(style.shoeType),
    sizeScale: new SizeScales().getDisplayName(style.sizeScale),
    sockPattern: new SockPatterns().getDisplayName(style.sockPattern),
    styleCode: style.styleCode,
    styleFirestoreDocumentId: style.firestoreDocumentId,
    styleGroup: style.styleGroup,
    styleRank: style.styleRank,
    styleType: style.styleType,
    supplies: new Supplies().getDisplayName(style.supplies),
    suspenderButtons: style.suspenderButtons,
    tieType: new TieTypes().getDisplayName(style.tieType),
    vent: new Vents().getDisplayName(style.vent),
    vestBack: new VestBacks().getDisplayName(style.vestBack),
    vestType: new VestTypes().getDisplayName(style.vestType),
    waist: new Waists().getDisplayName(style.waist),
    warehouses: new Warehouses().getDisplayNames(style.warehouses),
    webDiscontinuedDate: style.webDiscontinuedDate,
    webReleaseDate: style.webReleaseDate,
    metaDescription: style.metaDescription,
  };

  return ecomStyle;
}
