import { AppCheckErrorCode } from "../middleware";
import { AuthMiddlewareUnauthorized } from "../middleware/auth-middleware-response.type";

export const SendEmailChangeErrorCode = {
  // userNotFound: 'User not found',
  noNewEmail: 'New Email is required',
  noCurrentEmail: 'Current Email is required',
  noPreviousEmail: 'Previous Email is required',
  noCode: 'Code is required',
  firestoreError: 'Firestore Error',
  unknown: 'Unknown Error',
} as const;

export type SendEmailChangeErrorCode = typeof SendEmailChangeErrorCode[keyof typeof SendEmailChangeErrorCode];


type SendEmailChangeResponseSuccess = {
  success: true;
  docId: string;
}

type SendEmailChangeResponseError = {
  success: false;
  error: SendEmailChangeErrorCode;
} | AuthMiddlewareUnauthorized | AppCheckErrorCode;

export type SendEmailChangeResponse = SendEmailChangeResponseSuccess | SendEmailChangeResponseError;
