import { Order, PromoCode } from 'common-types';

export function markOrderForGroomsFree(order: Order): [Order, boolean] {
  console.log('in markOrderForGroomsFree');
  let needsUpdate: boolean = false;
  if (order.orderType === 'G' || order.orderType === 'W') {
    // Grooms Free
    let promoCode: PromoCode = PromoCode.GROOMSFREE;
    if (order.promos === undefined) {
      order.promos = [];
    }
    let i = order.promos.findIndex((existingPromoCode: PromoCode) => {
      return existingPromoCode === promoCode;
    });
    if (i > -1) {
      // Already in promo array. Don't update.
      console.log('already marked for grooms free. do not need to update');
      needsUpdate = false;
      return [order, needsUpdate];
    } else {
      console.log('not marked for grooms free. need to update order');
      needsUpdate = true;
      order.promos = [];
      order.promos.push(promoCode);
      order.promoDetails = [];
      order.promoDetails.push({
        code: promoCode,
      });
      return [order, needsUpdate];
    }
  } else if (order.orderType === 'H') {
    // Honor Escort
    let promoCode: PromoCode = PromoCode.QUINCEFREE;
    if (order.promos === undefined) {
      order.promos = [];
    }
    let i = order.promos.findIndex((existingPromoCode: PromoCode) => {
      return existingPromoCode === promoCode;
    });
    if (i > -1) {
      // Already in promo array. Don't update.
      console.log('already marked for quince free. do not need to update');
      needsUpdate = false;
      return [order, needsUpdate];
    } else {
      console.log('not marked for quince free. need to update order');
      needsUpdate = true;
      order.promos = [];
      order.promos.push(promoCode);
      order.promoDetails = [];
      order.promoDetails.push({
        code: promoCode,
      });
      return [order, needsUpdate];
    }
  } else {
    console.log('not a groom or honor escort. do not update');
    return [order, needsUpdate];
  }
}
