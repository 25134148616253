import { AccountCommissionGrouping, Commission } from 'common-types';

export function groupUnpaidCommissionsByAccount(
  commissions: Array<Commission>
): Array<AccountCommissionGrouping> {
  let grouping: Array<AccountCommissionGrouping> = [];
  commissions.forEach((commission: Commission) => {
    if (commission.approved !== undefined && commission.paid === undefined) {
      // approved, but not paid.
      let existingAccountIndex: number = grouping.findIndex(
        (accountGrouping: AccountCommissionGrouping) => {
          return (
            commission.commissionAccountNumber ===
            accountGrouping.commissionAccountNumber
          );
        }
      );
      if (existingAccountIndex > -1) {
        // Already in the grouping. Add the commission.
        grouping[existingAccountIndex].commissions.push(commission);
        grouping[existingAccountIndex].purchaseCommission +=
          commission.purchaseCommission;
        grouping[existingAccountIndex].rentalCommission +=
          commission.rentalCommission;
        grouping[existingAccountIndex].totalCommission +=
          commission.totalCommission;
      } else {
        // new account entry
        let newAccount: AccountCommissionGrouping = {
          commissionAccountNumber: commission.commissionAccountNumber,
          commissionStatus: 'unpaid',
          purchaseCommission: commission.purchaseCommission,
          rentalCommission: commission.rentalCommission,
          totalCommission: commission.totalCommission,
          commissions: [commission],
        };
        grouping.push(newAccount);
      }
    }
  });
  return grouping;
}
