import { FilterCategory, FilterSelect, StyleType } from 'common-types';

export function getFilterCategoriesByStyleType(
  styleType: string
): Array<FilterCategory> {
  const defaultCategories: Array<FilterCategory> = [
    {
      attribute: 'colorFamily',
      displayName: 'Color Family',
      select: FilterSelect.Single,
    },
    // {
    //   attribute: 'styleGroup',
    //   displayName: 'Rent or Buy',
    //   select: FilterSelect.Multi,
    // },
  ];

  let extraCategories: Array<FilterCategory>;
  switch (styleType) {
    case StyleType.Coat:
      extraCategories = [
        {
          attribute: 'designer',
          displayName: 'Designer',
        },
      ];
      break;
    case StyleType.Jewelry:
      extraCategories = [];
      break;
    case StyleType.Shoes:
      extraCategories = [];
      break;
    case StyleType.Socks:
      extraCategories = [];
      break;
    case StyleType.Tie:
      extraCategories = [
        {
          attribute: 'tieType',
          displayName: 'Tie Style',
          select: FilterSelect.Multi,
        },
        {
          attribute: 'pattern',
          displayName: 'Pattern',
          select: FilterSelect.Multi,
        },
      ];
      break;
    case StyleType.Vest:
      extraCategories = [
        {
          attribute: 'pattern',
          displayName: 'Pattern',
          select: FilterSelect.Multi,
        },
      ];
      break;
    default:
      extraCategories = [];
  }

  return defaultCategories.concat(extraCategories);
}
