import { EcomEnsemble, EcomStyle, Image } from 'common-types';

export function getOriginalImage(
  object: EcomStyle | EcomEnsemble | Partial<EcomStyle> | undefined
): Image {
  let image: Image = {
    src: 'assets/images/style-image-placeholder.png',
    alt: 'image placeholder',
  };
  if (object) {
    if (object.images) {
      if (object.images.length > 0) {
        image.src = object.images[0].src;
        image.alt = object.images[0].alt;
      }
    }
  }

  return image;
}
