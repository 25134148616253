import { Look } from 'common-types';
import { getStyleTypeByStyleCodePrefix } from './get-style-type-by-prefix';

export function getMatchingStyleCodesByStyleType(
  styleType: string,
  look: Look
): Array<string> {
  const matchingStyleCodes: Array<string> = [];
  // getStyleTypeByStyleCodePrefix(styleType);
  look.styles.forEach((style) => {
    style.matchingStyles?.forEach((style) => {
      if (styleType !== getStyleTypeByStyleCodePrefix(style.styleCode)) {
        matchingStyleCodes.push(style.styleCode);
      }
    });
    if (styleType !== getStyleTypeByStyleCodePrefix(style.styleCode)) {
      matchingStyleCodes.push(style.styleCode);
    }
  });
  return [...new Set(matchingStyleCodes)];
}
