import { Event } from 'common-types';
import { isUserOrganizerOrAdminForEvent } from './is-user-organizer-or-admin-for-event';

export function getMemberView(event: Event): boolean {
  let lsUser = JSON.parse(localStorage.getItem('user')!);
  if (isUserOrganizerOrAdminForEvent(event)) {
    return false;
  }
  if (lsUser !== null) {
    return !event.isSingleUser && lsUser.uid !== event.createdByUserId;
  }
  return false;
}
