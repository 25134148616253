import { DealerUserEvent } from "../../../dealer/dealer-user-event.type";

// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export type NonSpecificErrorResponse = any;



// ********************************************************************************************************************
// *                                             CREATE                                                               *
// ********************************************************************************************************************







// ********************************************************************************************************************
// *                                             READ                                                                 *
// ********************************************************************************************************************
export type GetDealerUserEventByEventIdSuccessResponse = DealerUserEvent;
export type GetDealerUserEventByEventIdResponse = GetDealerUserEventByEventIdSuccessResponse | NonSpecificErrorResponse;


export type GetDealerUserEventsByUserIdSuccessResponse = DealerUserEvent[];
export type GetDealerUserEventsByUserIdResponse = GetDealerUserEventsByUserIdSuccessResponse | NonSpecificErrorResponse;

export type GetNonTransferredEventsByUserIdSuccessResponse = DealerUserEvent[];
export type GetNonTransferredEventsByUserIdResponse = GetNonTransferredEventsByUserIdSuccessResponse | NonSpecificErrorResponse;

export type GetEventsNotAcceptedByUserIdSuccessResponse = DealerUserEvent[];
export type GetEventsNotAcceptedByUserIdResponse = GetEventsNotAcceptedByUserIdSuccessResponse | NonSpecificErrorResponse;


// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************

export type UpdateDealerUserEventByIdData = {
  eventId: string,
  dealerUserEvent: DealerUserEvent
}
export type UpdateDealerUserEventByIdSuccessResponse = DealerUserEvent;
export type UpdateDealerUserEventByIdResponse = UpdateDealerUserEventByIdSuccessResponse | NonSpecificErrorResponse;


export type MarkEventAsTransferredSuccessResponse = DealerUserEvent;
export type MarkEventAsTransferredResponse = MarkEventAsTransferredSuccessResponse | NonSpecificErrorResponse | string;




// ********************************************************************************************************************
// *                                             DELETE                                                               *
// ********************************************************************************************************************
