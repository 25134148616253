export function getLegacyCoatSize(coatSize: string): [string, string] {
  let coatSizeArray = coatSize.split(' ');
  if (coatSizeArray.length < 2) {
    // Should be at least 2. Extra Long may have 3.
    throw new Error(
      'Error parsing coat size to legacy coat size. No space between coat size and length?'
    );
  }
  let legacyCoatSize = coatSizeArray[0];
  if (legacyCoatSize.length !== 2) {
    // boys sizes can be 3 Boys. need to convert to 03
    legacyCoatSize = '0' + legacyCoatSize;
  }
  let legacyCoatLength = coatSizeArray[1];
  switch (legacyCoatLength) {
    case 'Boys':
      legacyCoatLength = 'B';
      break;
    case 'Husky':
      legacyCoatLength = 'H';
      break;
    case 'Short':
      legacyCoatLength = 'S';
      break;
    case 'Regular':
      legacyCoatLength = 'R';
      break;
    case 'Portly':
      legacyCoatLength = 'P';
      break;
    case 'Long':
      legacyCoatLength = 'L';
      break;
    case 'Extra':
      legacyCoatLength = 'X';
      break;
    default:
      legacyCoatLength = '';
      break;
  }
  if (legacyCoatLength === '') {
    throw new Error('Unable to extract legacyCoatLength from coat size.');
  }
  return [legacyCoatSize, legacyCoatLength];
}
