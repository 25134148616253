export const HeightRange: string[] = [
  '2\'0"',
  '2\'1"',
  '2\'2"',
  '2\'3"',
  '2\'4"',
  '2\'5"',
  '2\'6"',
  '2\'7"',
  '2\'8"',
  '2\'9"',
  '2\'10"',
  '2\'11"',

  '3\'0"',
  '3\'1"',
  '3\'2"',
  '3\'3"',
  '3\'4"',
  '3\'5"',
  '3\'6"',
  '3\'7"',
  '3\'8"',
  '3\'9"',
  '3\'10"',
  '3\'11"',

  '4\'0"',
  '4\'1"',
  '4\'2"',
  '4\'3"',
  '4\'4"',
  '4\'5"',
  '4\'6"',
  '4\'7"',
  '4\'8"',
  '4\'9"',
  '4\'10"',
  '4\'11"',

  '5\'0"',
  '5\'1"',
  '5\'2"',
  '5\'3"',
  '5\'4"',
  '5\'5"',
  '5\'6"',
  '5\'7"',
  '5\'8"',
  '5\'9"',
  '5\'10"',
  '5\'11"',

  '6\'0"',
  '6\'1"',
  '6\'2"',
  '6\'3"',
  '6\'4"',
  '6\'5"',
  '6\'6"',
  '6\'7"',
  '6\'8"',
  '6\'9"',
  '6\'10"',
  '6\'11"',

  '7\'0"',
  '7\'1"',
  '7\'2"',
];

export const CoatSizeRange: string[] = [
  '3 Boys',
  '4 Boys',
  '5 Boys',
  '6 Boys',
  '7 Boys',
  '8 Boys',
  '10 Boys',
  '12 Boys',
  '14 Boys',
  '16 Boys',
  '18 Boys',
  '34 Regular',
  '34 Short',
  '34 Long',
  '35 Regular',
  '35 Short',
  '35 Long',
  '36 Regular',
  '36 Short',
  '36 Long',
  '37 Regular',
  '37 Short',
  '37 Long',
  '38 Regular',
  '38 Short',
  '38 Long',
  '38 Extra Long',
  '39 Regular',
  '39 Short',
  '39 Long',
  '39 Extra Long',
  '40 Regular',
  '40 Short',
  '40 Long',
  '40 Extra Long',
  '41 Regular',
  '41 Short',
  '41 Long',
  '41 Extra Long',
  '42 Regular',
  '42 Short',
  '42 Long',
  '42 Extra Long',
  '43 Regular',
  '43 Short',
  '43 Long',
  '43 Extra Long',
  '44 Regular',
  '44 Short',
  '44 Long',
  '44 Extra Long',
  '45 Regular',
  '45 Short',
  '45 Long',
  '45 Extra Long',
  '46 Regular',
  '46 Short',
  '46 Long',
  '46 Extra Long',
  '47 Regular',
  '47 Short',
  '47 Long',
  '48 Regular',
  '48 Short',
  '48 Long',
  '48 Extra Long',
  '50 Regular',
  '50 Short',
  '50 Long',
  '50 Extra Long',
  '52 Regular',
  '52 Short',
  '52 Long',
  '52 Extra Long',
  '54 Regular',
  '54 Short',
  '54 Long',
  '54 Extra Long',
  '56 Regular',
  '56 Short',
  '56 Long',
  '56 Extra Long',
  '58 Regular',
  '58 Short',
  '58 Long',
  '58 Extra Long',
  '60 Regular',
  '60 Short',
  '60 Long',
  '60 Extra Long',
  '62 Regular',
  '62 Long',
  '62 Extra Long',
  '64 Regular',
  '64 Long',
  '64 Extra Long',
  '66 Regular',
  '66 Long',
  '66 Extra Long',
  '68 Regular',
  '68 Long',
  '70 Regular',
  '70 Long',
  '72 Regular',
  '72 Long',
  '74 Regular',
  '74 Long',
];

export const ShoeSizeRange: string[] = [
  '6',
  '6 1/2',
  '6 1/2 Wide',
  '7',
  '7 Wide',
  '7 1/2',
  '7 1/2 Wide',
  '8',
  '8 Wide',
  '8 1/2',
  '8 1/2 Wide',
  '9',
  '9 Wide',
  '9 1/2',
  '9 1/2 Wide',
  '10',
  '10 Wide',
  '10 1/2',
  '10 1/2 Wide',
  '11',
  '11 Wide',
  '11 1/2',
  '11 1/2 Wide',
  '12',
  '12 Wide',
  '13',
  '13 Wide',
  '14',
  '14 Wide',
  '15',
  '15 Wide',
  '16',
  '16 Wide',
  '17',
  '17 Wide',
  '18',
  '18 Wide',
  '7 Toddler',
  '7 1/2 Toddler',
  '8 Toddler',
  '8 1/2 Toddler',
  '9 Toddler',
  '9 1/2 Toddler',
  '10 Toddler',
  '10 1/2 Toddler',
  '11 Toddler',
  '11 1/2 Toddler',
  '12 Toddler',
  '12 1/2 Toddler',
  '13 Toddler',
  '13 1/2 Toddler',
  '1 Boys',
  '1 1/2 Boys',
  '2 Boys',
  '2 1/2 Boys',
  '3 Boys',
  '3 1/2 Boys',
  '4 Boys',
  '4 1/2 Boys',
  '5 Boys',
  '5 1/2 Boys',
];

export const WeightRange: number[] = generateRange(22, 450, 1);
export const ChestRange: number[] = generateRange(20, 74, 1);
export const OverarmRange: number[] = generateRange(21, 83, 1);
export const StomachRange: number[] = generateRange(18, 74, 0.5);
export const WaistRange: number[] = generateRange(18, 71, 1);
export const HipRange: number[] = generateRange(18, 74, 1);
export const OutseamRange: number[] = generateRange(15, 55, 0.5);
export const NeckRange: number[] = generateRange(10, 28, 0.5);
export const SleeveRange: number[] = generateRange(16, 41, 1);

function generateRange(start: number, end: number, increment: number) {
  let range: Array<number> = [];
  while (start < end) {
    range.push(start);
    start = start + increment;
  }
  return range;
}
