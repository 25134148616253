// These are the types of mannequin accessory images.
// They are listed in the order they must be added to the accessory panel.
// The string value can be assigned to the image's mannequinImage field.

export enum MannequinAccessoryImageType {
  Shoes = 'Shoes',
  PocketSquare = 'PocketSquare',
  Socks = 'Socks',
  Suspenders = 'Suspenders',
  Miscellaneous = 'Miscellaneous',
  Jewelry = 'Jewelry',
}
