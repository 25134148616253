import {
  Account as AccountInterface,
  CanBeValidated,
  Db2AccountAttributes,
} from 'common-types';
import { ZodError } from 'zod';
import { AccountValidator } from './validation/account-validator';

export class Account implements AccountInterface, CanBeValidated {
  id?: string | undefined;
  name?: string | undefined;

  db2AccountAttributes?: Db2AccountAttributes | undefined;

  getErrors(): ZodError | undefined {
    try {
      AccountValidator.parse(this);
    } catch (err) {
      if (err instanceof ZodError) {
        return err;
      } else {
        throw err;
      }
    }
    return undefined;
  }

  isValid(): boolean {
    try {
      AccountValidator.parse(this);
      return true;
    } catch (err) {
      if (err instanceof ZodError) {
        return false;
      } else {
        throw err;
      }
    }
  }
}
