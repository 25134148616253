import * as z from 'zod';

export const AddressSchema = z.object({
  streetAddress1: z.string(),
  streetAddress2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  countryCode: z.string().optional(),
  upsValidated: z.boolean().optional(),
});

export type ZodAddress = z.infer<typeof AddressSchema>;


export interface Address {
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zip: string;
  countryCode?: string;
  upsValidated?: boolean;
}
