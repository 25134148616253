import * as z from 'zod';
import { PhoneObjectSchema } from '../phone';
import { DateAsObjectSchema } from '../date';

export const EmailUsFormSchema = z
  .object(
    {
      firstName: z
        .string({ invalid_type_error: 'First Name is required' })
        .min(1, 'First Name is required'),
      lastName: z
        .string({ invalid_type_error: 'Last Name is required' })
        .min(1, 'Last name is required'),
      email: z
        .string({ invalid_type_error: 'Email is required' })
        .email('Please enter a valid email address')
        .toLowerCase()
        .trim()
        .min(1, 'Email is required'),
      phone: PhoneObjectSchema.optional(),
      eventDate: DateAsObjectSchema.optional(),
      comments: z
        .string({ invalid_type_error: 'Comments are required' })
        .min(1, 'Comments are required'),
      recaptcha: z.object(
        {
          token: z.string().min(1, 'Captcha token is required'),
          action: z.string().min(1, 'Captcha action is required'),
        },
        { required_error: 'Captcha is required' }
      ),
    }
    // { invalid_type_error: 'Invalid Type' }
  )
  .strict();

export interface EmailUsForm extends z.infer<typeof EmailUsFormSchema> {}
