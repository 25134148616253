import { CommissionTypeEnum } from 'common-types';

export function getIndividualCommissionByProductId(
  commissionType: CommissionTypeEnum,
  productId: string
): number {
  switch (productId) {
    case 'CT':
      return 15.0;
    case 'PT':
      return 10.0;
    case 'SR':
      return 5.0;
    case 'TI':
      return 5.0;
    case 'VS':
      return 5.0;
    case 'SO':
      switch (commissionType) {
        case CommissionTypeEnum.Referral:
          return 10.0;
        case CommissionTypeEnum.CustomLink:
        case CommissionTypeEnum.Organic:
          return 0.0;
        default:
          return 0.0;
      }
    default:
      return 0.0;
  }
}
