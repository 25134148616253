import { EcomStyle, StyleGroup, StyleType } from 'common-types';

export function getEmptyStyles(): EcomStyle[] {
  return [
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Coat,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Pant,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Shirt,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Tie,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Vest,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Shoes,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.PocketSquare,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Socks,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Suspenders,
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Miscellaneous,
      miscellaneousType: 'Belt',
    },
    {
      styleCode: '0',
      styleGroup: StyleGroup.Unassigned,
      styleType: StyleType.Jewelry,
    },
  ];
}
