import { DealerAccountElastic } from 'common-types';

export const getSelectedStoreFromLocalStorage =
  (): DealerAccountElastic | null => {
    const selectedStore = localStorage.getItem('selected_store');
    if (selectedStore) {
      const parsedStore = JSON.parse(selectedStore) as DealerAccountElastic;
      return parsedStore;
    } else {
      return null;
    }
  };
