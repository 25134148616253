import { Look, StyleType } from 'common-types';
import { getStyleBuyPrice } from './get-style-buy-price';
import { getStyleRentPrice } from './get-style-rent-price';

export function getTotalRentPrice(look: Look) {
  // Returns rent subtotal. If purchase only items are added, it is also included in subtotal.

  let subTotal = 0;

  const styles = look.styles;
  const stylesIncludedInRent = [
    StyleType.Shirt,
    StyleType.Pant,
    StyleType.Vest,
    StyleType.Tie,
  ];

  for (let style of styles) {
    const rentPrice = getStyleRentPrice(style);

    if (style.styleType === StyleType.Coat) {
      subTotal += rentPrice ?? 0;
    } else if (!stylesIncludedInRent.includes(style.styleType)) {
      // basic jewelry is included with ensemble, premium is not
      const isBasicJewelry =
        style.styleType === 'Jewelry' && style.collection !== 'Premium (PRM)';

      if (!isBasicJewelry) {
        if (rentPrice) {
          subTotal += rentPrice;
        } else {
          const buyPrice = getStyleBuyPrice(style) ?? 0;
          subTotal += buyPrice;
        }
      }
    }
  }

  return subTotal;
}
