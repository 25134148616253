import { Injectable } from '@angular/core';
import { ColumnHeader, LinkType, NavListItem } from 'common-types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  showHeaderNav = new Subject<boolean>();
  showPlpNav = new Subject<boolean>();
  showCustomizeLookNav = new Subject<boolean>();
  showMobileSearch = new Subject<boolean>();
  collapsableHeader = new Subject<boolean>();
  showSearchNav = new Subject<boolean>();
  showSearchDrawer = new Subject<boolean>();

  get howItWorksNav(): NavListItem[] {
    return [
      {
        title: 'Ways to Rent or Buy',
        link: '/ways-to-rent-or-buy',
        linkType: LinkType.Route,
      },
      {
        title: 'Our Process',
        link: '/our-process',
        linkType: LinkType.Route,
      },
      {
        title: 'Why Choose JFW',
        link: '/why-choose-jfw',
        linkType: LinkType.Route,
      },
      {
        title: 'Out-of-Town Groomsmen',
        link: '/out-of-town-groomsmen',
        linkType: LinkType.Route,
      },
      {
        title: 'Getting Measured',
        link: '/getting-measured',
        linkType: LinkType.Route,
      },
      {
        title: 'FAQs',
        link: '/faq',
        linkType: LinkType.Route,
        clickFunction: 'console.log("example click function");',
      },
    ];
  }

  get inspirationNav(): NavListItem[] {
    return [
      {
        title: 'Blog',
        link: 'https://blog.jimsformalwear.com',
        linkType: LinkType.Url,
        target: '_blank'
      },
      {
        title: 'Free Swatches',
        link: '/free-swatches',
        linkType: LinkType.Route,
      },
    ];
  }

  get myAccountNav(): NavListItem[] {
    return [
      {
        title: 'Account Info',
        link: '/my-account/account-info',
        linkType: LinkType.Route,
      },
      {
        title: 'Events',
        link: '/my-account/events',
        linkType: LinkType.Route,
      },
    ];
  }

  get alphaNav(): NavListItem[] {
    return [
      {
        title: 'Local Storage',
        link: '/debug/local-storage',
        linkType: LinkType.Route,
      },
    ];
  }

  get supportNav(): NavListItem[] {
    return [
      {
        title: 'Contact Us',
        link: '/contact-us',
        linkType: LinkType.Route,
      },
      {
        title: 'Returns & Exchanges',
        link: '/returns-and-exchanges',
        linkType: LinkType.Route,
      },
      {
        title: 'Ways to Rent or Buy',
        link: '/ways-to-rent-or-buy',
        linkType: LinkType.Route,
      },
      {
        title: 'Get Measured',
        link: '/getting-measured',
        linkType: LinkType.Route,
      },
      {
        title: "FAQ's",
        link: '/faq',
        linkType: LinkType.Route,
      },
      {
        title: 'Free Swatches',
        link: '/free-swatches',
        linkType: LinkType.Route,
      },
      {
        title: 'Special Offers',
        link: '/special-offers',
        linkType: LinkType.Route,
      },
      // {
      //   title: 'Contests',
      //   link: '/contests',
      //   linkType: LinkType.Route,
      // },
      // {
      //   title: 'Site Map',
      //   link: '/sitemap',
      //   linkType: LinkType.Route,
      // },
    ];
  }

  get forRetailersNav(): NavListItem[] {
    return [
      {
        title: 'Become a JFW Retailer',
        link: '/become-a-jfw-retailer',
        linkType: LinkType.Route,
      },
      {
        title: 'Become a Referral Partner',
        link: '/become-a-referral-partner',
        linkType: LinkType.Route,
      },
    ];
  }

  get aboutUsNav(): NavListItem[] {
    return [
      // {
      //   title: 'Careers',
      //   link: '/careers',
      //   linkType: LinkType.Route,
      // },
      {
        title: 'The JFW Story',
        link: '/the-jfw-story',
        linkType: LinkType.Route,
      },
      {
        title: 'Charity Work',
        link: '/charity-work',
        linkType: LinkType.Route,
      },
      {
        title: 'Privacy',
        link: '/privacy',
        linkType: LinkType.Route,
      },
      {
        title: 'Terms of Use',
        link: '/terms-of-use',
        linkType: LinkType.Route,
      },
      {
        title: 'Accessibility',
        link: '/accessibility',
        linkType: LinkType.Route,
      },
    ];
  }

  get commissionHeader(): ColumnHeader[] {
    return [
      {
        title: 'Event Date',
        value: 'eventDate',
      },
      {
        title: 'Event Name',
        value: 'eventName',
      },
      {
        title: 'Name',
        value: '',
      },
      {
        title: 'Role',
        value: '',
      },
      {
        title: 'Rental Ensemble Commission',
        value: 'rentalEnsembleCommission',
      },
      {
        title: 'Product Purchased Commission',
        value: 'purchasedProductCommission',
      },
      {
        title: 'Total Dealer Commission',
        value: 'totalDealerCommission',
      },
      {
        title: 'Referral Associate',
        value: 'referralAssociate',
      },
    ];
  }

  get expandedCommissionHeader(): ColumnHeader[] {
    return [
      {
        title: 'Event Date',
        value: 'eventDate',
      },
      {
        title: 'Event Name',
        value: 'eventName',
      },
      {
        title: 'Name',
        value: '',
      },
      {
        title: 'Role',
        value: '',
      },
      {
        title: 'Rental Ensemble Commission',
        value: 'rentalEnsembleCommission',
      },
      {
        title: 'Product Purchased Commission',
        value: 'purchasedProductCommission',
      },
      {
        title: 'Total Dealer Commission',
        value: 'totalDealerCommission',
      },
      {
        title: 'Referral Associate',
        value: 'referralAssociate',
      },
    ];
  }
}
