import * as z from 'zod';
import {
  ShippingAddressSchema
} from '../address/shipping-address.interface';
import {
  MeasurementsSchema
} from '../checkout/measurements.type';
import {
  MemberProgressSchema
} from '../checkout/member-progress.type';
import { LookSchema } from '../ensemble/look.interface';
import { OrderDetailSchema } from '../order/order-detail.type';

export const EventMemberSchema = z.object({
  id: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  memberRole: z.string(),
  memberLook: LookSchema.optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  shippingAddress: ShippingAddressSchema.optional(),
  measurements: MeasurementsSchema.optional(),
  surrogateUser: z.boolean().optional(),
  acceptsTexts: z.boolean().optional(),
  userPaying: z.string().optional(),
  memberProgress: MemberProgressSchema.optional(),
  userId: z.string().optional(),
  firestoreOrderId: z.string().optional(),
  internalOrderNumber: z.number().optional(),
  orderNumber: z.number().optional(),
  notificationDateTimeToGetMeasured: z.number().optional(),
  notificationDateTimeToOrder: z.number().optional(),
  notificationDateTimeToReturnOrder: z.number().optional(),
  notificationDateTimeFeedback: z.number().optional(),
  orderDetails: z.array(OrderDetailSchema).optional(),
  accountNumber: z.string().optional(),
  orderStatus: z.string().optional(),
});

export type EventMember = z.infer<typeof EventMemberSchema>;
// export interface EventMember extends z.infer<typeof EventMemberSchema> { }

// export type EventMember = {
//   id?: string;
//   firstName?: string;
//   lastName?: string;
//   memberRole: string;
//   memberLook?: Look;
//   phone?: string;
//   email?: string;
//   shippingAddress?: ShippingAddress;
//   measurements?: Measurements;
//   surrogateUser?: boolean;
//   acceptsTexts?: boolean;
//   userPaying?: string;
//   memberProgress?: MemberProgress;
//   userId?: string;
//   firestoreOrderId?: string;
//   internalOrderNumber?: number;
//   orderNumber?: number;
//   notificationDateTimeToGetMeasured?: number;
//   notificationDateTimeToOrder?: number;
//   notificationDateTimeToReturnOrder?: number;
//   notificationDateTimeFeedback?: number;
//   orderDetails?: Array<OrderDetail>;
//   accountNumber?: string;
//   orderStatus?: string;
// };
