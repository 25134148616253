import { EcomEnsemble, EnsembleGroup } from 'common-types';

export function getEnsembleBuyPrice(ensemble: EcomEnsemble): number {
  let price: number | undefined;

  if (ensemble.ensembleGroup === EnsembleGroup.Purchase) {
    price = ensemble.price;
  } else if (ensemble.ensembleGroup === EnsembleGroup.Rental) {
    price = ensemble.companionEnsemblePrice;
  }

  if (price === undefined) {
    price = 0;
  }

  return price;
}
