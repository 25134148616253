import dayjs from 'dayjs';
import { StaticHolidays } from './static-holidays';
import { convertUnixTimestampToDate } from '../../date/utilities';

export function getDaysDiffFormatted(
  unixTimestamp: number,
  days: number,
  businessDays: boolean = false
) {
  const date = convertUnixTimestampToDate(unixTimestamp);

  let diffDate: dayjs.Dayjs;
  if (businessDays) {
    let diff = days > 0 ? 1 : -1;
    let i = 0;
    let prevDate = dayjs(date);
    let currentDate = prevDate;

    while (i !== days) {
      currentDate = dayjs(prevDate).add(diff, 'day');
      let isWeekend: boolean = false;
      let isHoliday: boolean = false;

      if (currentDate.day() === 0 || currentDate.day() === 6) {
        isWeekend = true;
      } else {
        for (let holiday of StaticHolidays) {
          if (
            dayjs(holiday).format('MM/DD/YY') === currentDate.format('MM/DD/YY')
          ) {
            isHoliday = true;
            break;
          }
        }
      }
      if (!isWeekend && !isHoliday) {
        i += diff;
      }
      prevDate = currentDate;
    }
    diffDate = currentDate;
  } else {
    diffDate = dayjs(date).add(days, 'day');
  }
  return dayjs(diffDate).format('MM/DD/YY');
}
