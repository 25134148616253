import { FilterCategory } from 'common-types';

export function getFilterCategoriesByEnsemble(): Array<FilterCategory> {
  return [
    {
      attribute: 'colorFamily',
      displayName: 'Color Family',
    },
    // EXT-1594 This will fetch back Rental and Purchase filter options but we will not use them. (For future use we will)
    {
      attribute: 'ensembleGroup',
      displayName: 'Rent or Buy',
    },
    {
      attribute: 'designer',
      displayName: 'Designer',
    },
  ];
}
