import { getDaysDiffFormatted } from "../../../calendar";

export function getResaleShippingDateRange(
  orderDate: number,
  resaleLeadTimeMin: number = 2,
  resaleLeadTimeMax: number = 7
) {
  let minDate = getDaysDiffFormatted(orderDate, resaleLeadTimeMin, true);
  let maxDate = getDaysDiffFormatted(orderDate, resaleLeadTimeMax, true);
  return [minDate, maxDate]; // As MM/DD/YY
}


