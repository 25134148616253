import * as z from 'zod';

export const OrderDetailSchema = z.object({
  // internalOrderNumber: z.number().optional(), -- not included in type, but needs to be passed via stored proc for DB2 to have relation.
  transactionLine: z.string(), // not including - used for web service interaction.
  line: z.number(), // ORDDTL.LINE NUMERIC 2 , 0
  avalaraLineId: z.string().optional(),
  productId: z.string(), // ORDDTL.ORPROD CHAR 4
  style: z.string(), // ORDDTL.ORSTYL CHAR 6
  option1: z.string(), // ORDDTL.OROPT1 CHAR 5
  option2: z.string(), // ORDDTL.OROPT2 CHAR 5
  option3: z.string().optional(), // ORDDTL.OROPT3 CHAR 5
  quantityOrdered: z.number(), // ORDDTL.QTYORD NUMERIC 3, 0
  replacementCode: z.string().optional(), // ORDDTL.REPL CHAR 1
  replacementLine: z.number().optional(), // NUMERIC 3, 0 -- not including - not referenced in order tables.
  qualityDescription: z.string().optional(), // QIIITEM.TEXT CHAR 70
  warehouse: z.string(), // ORDDTL.WHSE CHAR 2
  carrier: z.string().optional(), // ORDDTL.CARR CHAR 2
  carrierService: z.string().optional(), // ORDDTL.CARSRV CHAR 4
  adjustedShipDate: z.string(), // ORDDTL.ASHPDAT (not including , ORDPRD has the true one, but isn't in detail).
  detailText: z.string().optional(), // ORDTEXT.TEXT CHAR 50 -- may contain sensitive info. unsure if to bring it along with sync
  lineStatus: z.string().optional(), // ORDDTL.ORDSTS CHAR 1
  trackingNumber: z.string().optional(), // ORDTRACK.TRKNUM CHAR 18
  detailError: z.string().optional(), // not including.
  notificationDateOrderShipped: z.number().optional(), // not including, just for recording when a notification is sent for this OrderDetail
  styleGroup: z.string().optional(),
  price: z.number().optional(),
  chargeCode: z.string().optional(),
  styleCode: z.string().optional(),
  description: z.string().optional(),
});

export type ZodOrderDetail = z.infer<typeof OrderDetailSchema>;

export type OrderDetail = {
  // internalOrderNumber: number; -- not included in type, but needs to be passed via stored proc for DB2 to have relation.
  transactionLine: string; // not including - used for web service interaction.
  line: number; // ORDDTL.LINE NUMERIC 2 , 0
  avalaraLineId?: string;
  productId: string; // ORDDTL.ORPROD CHAR 4
  style: string; // ORDDTL.ORSTYL CHAR 6
  option1: string; // ORDDTL.OROPT1 CHAR 5
  option2: string; // ORDDTL.OROPT2 CHAR 5
  option3?: string; // ORDDTL.OROPT3 CHAR 5
  quantityOrdered: number; // ORDDTL.QTYORD NUMERIC 3, 0
  replacementCode?: string; // ORDDTL.REPL CHAR 1
  replacementLine?: number; // NUMERIC 3, 0 -- not including - not referenced in order tables.
  qualityDescription?: string; // QIIITEM.TEXT CHAR 70
  warehouse: string; // ORDDTL.WHSE CHAR 2
  carrier?: string; // ORDDTL.CARR CHAR 2
  carrierService?: string; // ORDDTL.CARSRV CHAR 4
  adjustedShipDate: string; // ORDDTL.ASHPDAT (not including , ORDPRD has the true one, but isn't in detail).
  detailText?: string; // ORDTEXT.TEXT CHAR 50 -- may contain sensitive info. unsure if to bring it along with sync
  lineStatus?: string; // ORDDTL.ORDSTS CHAR 1
  trackingNumber?: string; // ORDTRACK.TRKNUM CHAR 18
  detailError?: string; // not including.
  notificationDateOrderShipped?: number; // not including, just for recording when a ORDER-SHIPPED notification is sent for this OrderDetail
  notificationDateReplacementShipped?: number; // not including, just for recording when a REPLACEMENT-SHIPPED notification is sent for this OrderDetail
  styleGroup?: string;
  price?: number;
  chargeCode?: string;
  styleCode?: string;
  description?: string;
};
