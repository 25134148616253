import { z, ZodSchema } from 'zod';

export const ShippingAddressValidator: ZodSchema = z.object({
  name: z.string().max(100),
  streetAddress1: z.string().max(150),
  streetAddress2: z.string().max(150).optional(),
  city: z.string().max(35),
  state: z.string().length(2),
  zip: z.string().max(10),
  countryCode: z.string().max(3).optional(),
  phone: z.string().max(12).optional(),
  shippingMethod: z.string().max(15),
});
