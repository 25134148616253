// api is currently in api-internal/event/src/inviteV2.ts (but is deployed to Ecom Firebase project)

import { InviteStatus } from '../../..//landing-page';

// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const UnauthorizedUserResponse = "Unauthorized" as const;
export type UnauthorizedUserResponse = typeof UnauthorizedUserResponse;
export type NonSpecificErrorResponse = 'error';


export type VerifyEventInviteData = {
  eventId: string,
  emailAddress: string
}
export type VerifyEventInviteSuccessResponse = InviteStatus | NonSpecificErrorResponse;  // These are both sent with a 200 status code
export type VerifyEventInviteResponse = VerifyEventInviteSuccessResponse;
