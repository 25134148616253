import {
  CommissionDetail,
  CommissionTypeEnum,
  Order,
  OrderDetail,
} from 'common-types';
import { isRentalEnsemble } from '../utilities/is-rental-ensemble';
import { getIndividualCommissionByProductId } from './get-individual-commission-by-product-id';
import { getPurchaseCommission } from './get-purchase-commission';
import { isOrderRentalCommissionExempt } from './is-order-rental-commission-exempt';

export function getCommissionDetails(order: Order): Array<CommissionDetail> {
  const commissionDetails: Array<CommissionDetail> = [];
  const rentalEnsemble: boolean = isRentalEnsemble(order);
  const rentalCommissionExcept: boolean = isOrderRentalCommissionExempt(order);
  const commissionType: CommissionTypeEnum | undefined = order.commissionType;
  order.details.forEach((detail: OrderDetail) => {
    switch (commissionType) {
      case CommissionTypeEnum.Referral:
        if (detail.styleGroup === 'Rental') {
          // Rentals
          if (detail.productId === 'CT') {
            // Coat is different because of ensemble.
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              // not a replacement, potential commission.
              if (detail.lineStatus === 'D' || rentalCommissionExcept) {
                // Deleted or exempt, no commission.
                commissionDetails.push({
                  styleGroup: detail.styleGroup,
                  price: detail.price,
                  replacement: false,
                  legacyStyleCode: detail.style,
                  styleType: detail.productId,
                  lineStatus: detail.lineStatus,
                  commission: 0.0,
                  warehouse: detail.warehouse,
                  avalaraLineId: detail.avalaraLineId ?? '',
                });
              } else {
                // Not deleted
                commissionDetails.push({
                  styleGroup: detail.styleGroup,
                  price: detail.price,
                  replacement: false,
                  legacyStyleCode: detail.style,
                  styleType: detail.productId,
                  lineStatus: detail.lineStatus,
                  commission: rentalEnsemble
                    ? 40.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                  warehouse: detail.warehouse,
                  avalaraLineId: detail.avalaraLineId ?? '',
                });
              }
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          } else if (detail.productId === 'SO') {
            // Shoes are different from rest. Always gives the per price piece.
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: detail.price,
                replacement: false,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission:
                  detail.lineStatus === 'D' || rentalCommissionExcept
                    ? 0.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          } else {
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: detail.price,
                replacement: false,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission:
                  rentalEnsemble ||
                  detail.lineStatus === 'D' ||
                  rentalCommissionExcept
                    ? 0.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          }
        }
        if (detail.styleGroup === 'Purchase') {
          commissionDetails.push(getPurchaseCommission(detail));
        }
        break;
      case CommissionTypeEnum.Organic:
        if (detail.styleGroup === 'Rental') {
          // Rentals
          if (detail.productId === 'CT') {
            // Coat is different because of ensemble.
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              // not a replacement, potential commission.
              if (detail.lineStatus === 'D' || rentalCommissionExcept) {
                // Deleted or exempt, no commission.
                commissionDetails.push({
                  styleGroup: detail.styleGroup,
                  price: detail.price,
                  replacement: false,
                  legacyStyleCode: detail.style,
                  styleType: detail.productId,
                  lineStatus: detail.lineStatus,
                  commission: 0.0,
                  warehouse: detail.warehouse,
                  avalaraLineId: detail.avalaraLineId ?? '',
                });
              } else {
                // Not deleted
                commissionDetails.push({
                  styleGroup: detail.styleGroup,
                  price: detail.price,
                  replacement: false,
                  legacyStyleCode: detail.style,
                  styleType: detail.productId,
                  lineStatus: detail.lineStatus,
                  commission: rentalEnsemble
                    ? 25.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                  warehouse: detail.warehouse,
                  avalaraLineId: detail.avalaraLineId ?? '',
                });
              }
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          } else if (detail.productId === 'SO') {
            // Shoes are different from rest. Always gives the per price piece.
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: detail.price,
                replacement: false,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission:
                  detail.lineStatus === 'D' || rentalCommissionExcept
                    ? 0.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          } else {
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: detail.price,
                replacement: false,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission:
                  rentalEnsemble ||
                  detail.lineStatus === 'D' ||
                  rentalCommissionExcept
                    ? 0.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          }
        }
        if (detail.styleGroup === 'Purchase') {
          commissionDetails.push(getPurchaseCommission(detail));
        }
        break;
      case CommissionTypeEnum.CustomLink:
        if (detail.styleGroup === 'Rental') {
          // Rentals
          if (detail.productId === 'CT') {
            // Coat is different because of ensemble.
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              // not a replacement, potential commission.
              if (detail.lineStatus === 'D' || rentalCommissionExcept) {
                // Deleted or exempt, no commission.
                commissionDetails.push({
                  styleGroup: detail.styleGroup,
                  price: detail.price,
                  replacement: false,
                  legacyStyleCode: detail.style,
                  styleType: detail.productId,
                  lineStatus: detail.lineStatus,
                  commission: 0.0,
                  warehouse: detail.warehouse,
                  avalaraLineId: detail.avalaraLineId ?? '',
                });
              } else {
                // Not deleted
                commissionDetails.push({
                  styleGroup: detail.styleGroup,
                  price: detail.price,
                  replacement: false,
                  legacyStyleCode: detail.style,
                  styleType: detail.productId,
                  lineStatus: detail.lineStatus,
                  commission: rentalEnsemble
                    ? 35.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                  warehouse: detail.warehouse,
                  avalaraLineId: detail.avalaraLineId ?? '',
                });
              }
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          } else if (detail.productId === 'SO') {
            // Shoes are different from rest. Always gives the per price piece.
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: detail.price,
                replacement: false,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission:
                  detail.lineStatus === 'D' || rentalCommissionExcept
                    ? 0.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          } else {
            if (
              detail.replacementCode === undefined ||
              detail.replacementCode === ''
            ) {
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: detail.price,
                replacement: false,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission:
                  rentalEnsemble ||
                  detail.lineStatus === 'D' ||
                  rentalCommissionExcept
                    ? 0.0
                    : getIndividualCommissionByProductId(
                        commissionType,
                        detail.productId
                      ), // Ensemble or per piece.
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            } else {
              // Replacement
              commissionDetails.push({
                styleGroup: detail.styleGroup,
                price: 0,
                replacement: true,
                legacyStyleCode: detail.style,
                styleType: detail.productId,
                lineStatus: detail.lineStatus,
                commission: 0.0,
                warehouse: detail.warehouse,
                avalaraLineId: detail.avalaraLineId ?? '',
              });
            }
          }
        }
        if (detail.styleGroup === 'Purchase') {
          commissionDetails.push(getPurchaseCommission(detail));
        }
        break;
      default:
        break;
    }
  });
  return commissionDetails;
}
