import { storeHoursConverter } from './store-hours-converter';

export function storeHoursDisplayType(
  dayType: string | undefined,
  fromTime: string | undefined,
  toTime: string | undefined
): string {
  if (dayType === 'A') {
    return 'By Appt.';
  } else if (dayType === 'C') {
    return 'Closed';
  } else {
    return storeHoursConverter(fromTime, toTime);
  }
}
