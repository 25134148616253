import { Order, OrderDetail } from 'common-types';

export function isRentalEnsemble(order: Order): boolean {
  let hasRentalCoat: boolean = false;
  let hasRentalPant: boolean = false;
  let hasRentalOther: boolean = false;

  order.details.forEach((detail: OrderDetail) => {
    if (
      detail.productId === 'CT' &&
      detail.styleGroup === 'Rental' &&
      detail.lineStatus !== 'D'
    ) {
      hasRentalCoat = true;
    }
    if (
      detail.productId === 'PT' &&
      detail.styleGroup === 'Rental' &&
      detail.lineStatus !== 'D'
    ) {
      hasRentalPant = true;
    }
    if (
      detail.productId === 'SR' &&
      detail.styleGroup === 'Rental' &&
      detail.lineStatus !== 'D'
    ) {
      hasRentalOther = true;
    }
    if (
      detail.productId === 'TI' &&
      detail.styleGroup === 'Rental' &&
      detail.lineStatus !== 'D'
    ) {
      hasRentalOther = true;
    }
    if (
      detail.productId === 'VS' &&
      detail.styleGroup === 'Rental' &&
      detail.lineStatus !== 'D'
    ) {
      hasRentalOther = true;
    }
  });

  return hasRentalCoat && hasRentalPant && hasRentalOther;
}
