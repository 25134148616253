import { Order, OrderDetail } from 'common-types';

export function orderHasValidSizes(
  order: Order,
  checkCoat: boolean = true
): boolean {
  let isValid: boolean = true;
  order.details.forEach((detail: OrderDetail) => {
    switch (detail.productId) {
      case 'CT':
        if ((detail.option1 === '' || detail.option2 === '') && checkCoat) {
          isValid = false;
          break;
        } else {
          break;
        }
      case 'PT':
      case 'SR':
      case 'SO':
        if (detail.option1 === '' || detail.option2 === '') {
          isValid = false;
          break;
        } else {
          break;
        }
      case 'HS':
        if (detail.option1 === '') {
          isValid = false;
          break;
        } else {
          break;
        }
      default:
        break;
    }
  });
  return isValid;
}
