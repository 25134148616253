import { EcomStyle, Look, Measurements, OrderDetail } from 'common-types';
import { getLegacyProductId } from '../../product';
import { getLineNumber } from './get-line-number';
import { getSizeFromMeasurements } from './get-size-from-measurements';

export function convertLookToOrderDetails(
  look: Look,
  measurements: Measurements | undefined
): Array<OrderDetail> {
  let orderDetails: Array<OrderDetail> = [];
  look.styles.forEach((ecomStyle: EcomStyle) => {
    console.log('ecomStyle AvalaraLineId:', ecomStyle.avalaraLineId);
    let legacyProductId = getLegacyProductId(ecomStyle.styleType ?? ''); // probably need this defined.
    let lineNumber: number = getLineNumber(legacyProductId, orderDetails);
    if (lineNumber === -1) {
      // unable to sequence the product.
      throw new Error(
        `Unable to sequence product into orderDetails. product: ${{
          ecomStyle,
        }}, orderDetails: ${{ orderDetails }}`
      );
    }
    let sizeOption1 = '';
    let sizeOption2 = '';
    if (measurements !== undefined) {
      [sizeOption1, sizeOption2] = getSizeFromMeasurements(
        legacyProductId,
        measurements
      );
    }
    let warehouse: string = '';
    if (
      ecomStyle.warehouses !== undefined &&
      ecomStyle.warehouses.length === 1 &&
      ecomStyle.warehouses[0] == 'Ottawa'
    ) {
      warehouse = 'OT';
    }
    let orderDetail: OrderDetail = {
      productId: legacyProductId,
      line: lineNumber,
      transactionLine: 'ADDDTL',
      lineStatus: '',
      style: ecomStyle.legacyStyleCode ?? ecomStyle.styleCode.substring(3), // make sure that product.id is always styleCode?
      styleCode: ecomStyle.styleCode,
      option1: sizeOption1,
      option2: sizeOption2,
      option3: '',
      quantityOrdered: 1, // may need to add to product? only for non-barcoded items
      replacementCode: '',
      replacementLine: 0,
      qualityDescription: '',
      warehouse: warehouse, // may need to add logic to determine warehouse.
      carrier: '',
      carrierService: '',
      adjustedShipDate: '0001-01-01',
      detailText: '',
      styleGroup: ecomStyle.styleGroup,
      price: ecomStyle.price,
      avalaraLineId: ecomStyle.avalaraLineId,
    };
    orderDetails.push(orderDetail);
  });
  return orderDetails;
}
