import * as z from 'zod';

/**
 * Email validation schema for sign-in.  This is not the same as the email validation schema for creating a new account (JFWNewEmailSchema).
 */
export const JFWEmailSignInSchema = z
  .string({ invalid_type_error: "Email is required" }) // catch invalid type error like null
  .trim()
  .max(150, "Email can not be more than 150 characters")
  .min(1, "Email is required")
  .email("Please enter a valid email address");

export type JFWEmailSignIn = z.infer<typeof JFWEmailSignInSchema>;
