import * as z from 'zod';
import { Address, AddressSchema } from './address.interface';

export const ShippingAddressSchema = AddressSchema.extend({
  name: z.string(),
  phone: z.string(),
  shippingMethod: z.string().optional(),
});

export type ZodShippingAddress = z.infer<typeof ShippingAddressSchema>;

export interface ShippingAddress extends Address {
  name: string;
  phone: string;
  shippingMethod?: string;
}
