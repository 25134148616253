import * as z from 'zod';

export const MatchingStyleSchema = z.object({
  styleCode: z.string(),
  docId: z.string(), // the MatchingStyles Firestore document ID
});

// ORIGINAL TYPE
export type MatchingStyle = {
  styleCode: string;
  docId: string; // the MatchingStyles Firestore document ID
};

/*
  This displays as:
    const matchingStyle: MatchingStyle
*/
const matchingStyle: MatchingStyle = {
  styleCode: '',
  docId: '',
};

// OPTION 1: zod inferred type
export type ZodMatchingStyle = z.infer<typeof MatchingStyleSchema>;
/*
 This type displays as:
  const zodMatchingStyle: {
      styleCode: string;
      docId: string;
  }
  This is less ideal because you want to see a type as it’s type alias (name),
not as a listing of properties.
*/
const zodMatchingStyle: ZodMatchingStyle = {
  styleCode: '',
  docId: '',
};

// OPTION 2: interface that extends zod inferred type
export interface IMatchingStyle extends ZodMatchingStyle {}
/*
  This displays as:
    const imatchingStyle: MatchingStyle
*/
const imatchingStyle: IMatchingStyle = {
  styleCode: '',
  docId: '',
};

// OPTION 3: type that omits no properties from zod inferred type
export type ZodMatchingStyleType = Omit<ZodMatchingStyle, ''>;
/*
  This displays as:
    const zodMatchingStyleType: ZodMatchingStyleType
*/
const zodMatchingStyleType: ZodMatchingStyleType = {
  styleCode: '',
  docId: '',
};

// OPTION 4: interface in one step
export interface IMatchingStyleOneStep
  extends z.infer<typeof MatchingStyleSchema> {}
/*
  This displays as:
    const imatchingStyleOneStep: IMatchingStyleOneStep
*/
const imatchingStyleOneStep: IMatchingStyleOneStep = {
  styleCode: '',
  docId: '',
};
