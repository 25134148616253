import { Cart, MemberCart, TransactionLogDetail } from 'common-types';

export function convertCartToTransactionLogDetail(
  cart: Cart
): Array<TransactionLogDetail> {
  let details: Array<TransactionLogDetail> = [];
  let eventId: string = cart.eventId;
  let cartId: string = cart.id;

  if (cart.members !== undefined) {
    cart.members.forEach((member: MemberCart) => {
      let memberId: string = member.id;
      if (member.taxRecordResponse !== undefined) {
        let submittedDate: number = member.taxRecordResponse.submittedDate;
        if (
          member.taxRecordResponse.avalaraResponse !== undefined &&
          member.taxRecordResponse.avalaraResponse.lines !== undefined &&
          member.taxRecordResponse.avalaraResponse.lines.length > 0
        ) {
          let uniqueTransactionCode: string =
            member.taxRecordResponse.avalaraResponse.code;
          member.taxRecordResponse.avalaraResponse.lines.forEach(
            (line: any) => {
              let detail: TransactionLogDetail = {
                memberId: memberId,
                eventId: eventId,
                cartId: cartId,
                avalaraId: line.id,
                lineNumber: line.lineNumber,
                itemCode: line.description,
                amount: line.lineAmount,
                tax: line.tax,
                taxCode: line.taxCode,
                quantity: line.quantity,
                submittedDate: submittedDate,
                source: 'CART',
                styleCode: line.itemCode,
                customerCode:
                  member.taxRecordResponse?.avalaraResponse.customerCode,
                uniqueTransactionCode: uniqueTransactionCode,
              };
              details.push(detail);
            }
          );
        }
      }
    });
  }
  return details;
}
