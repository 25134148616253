// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const EmailNotFound = 'No user exists with the provided email' as const;
export const EmailAlreadyVerified = 'Email is already verified' as const;
export const UnknownErrorResponse = 'Unknown error' as const;

// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************

/// ************************ SEND VERIFICATION CODE ************************ ///
export type SendVerificationCodeSuccessResponse = void;

export const SendVerificationCodeFailureResponses = [
  EmailAlreadyVerified,
  EmailNotFound,
  UnknownErrorResponse,
];

export type SendVerificationCodeFailureResponseType =
  (typeof SendVerificationCodeFailureResponses)[number];

export type SendVerificationCodeResponse =
  | SendVerificationCodeSuccessResponse
  | SendVerificationCodeFailureResponseType;

/// ************************ VERIFY EMAIL ************************ ///
export type VerifyEmailSuccessResponse = void;

export const NoVerificationCodeFound =
  'No verification code found for this email' as const;
export const VerificationCodeDoesNotMatch =
  'Verification code does not match' as const;

export const VerifyEmailFailureResponses = [
  EmailAlreadyVerified,
  EmailNotFound,
  NoVerificationCodeFound,
  VerificationCodeDoesNotMatch,
  UnknownErrorResponse,
];
export type VerifyEmailFailureResponseType =
  (typeof VerifyEmailFailureResponses)[number];

export type VerifyEmailResponse =
  | VerifyEmailSuccessResponse
  | VerifyEmailFailureResponseType;
