import { getBusinessDays } from '../../../calendar';
import {
  convertDateToLegacyDate,
  convertUnixTimestampToDate,
} from '../../../date';

export function getLegacyPickUpDate(eventDate: number): string {
  let date: Date = convertUnixTimestampToDate(eventDate);
  while (getBusinessDays(date, date) === 0) {
    console.log('initial date is not a business day');
    date.setDate(date.getDate() - 1); // go back to the first business day.
    console.log('inital day set to: ', date);
  }
  /*
  if(date.getDay() === 6) { // Re-align to Friday if Saturday date.
    date.setDate(date.getDate() - 1);
  }
  if(date.getDay() === 0) { // Re-align to Friday if Sunday date.
    date.setDate(date.getDate() - 2);
  }
  */
  let pickupDate: Date = date;
  do {
    console.log('pickup date is....', pickupDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
  } while (getBusinessDays(pickupDate, date) !== 1);
  console.log('after subbing one day:', pickupDate);
  return convertDateToLegacyDate(pickupDate);
}
