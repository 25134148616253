import { EventMember, Look } from 'common-types';
import { isPaidForMember } from '../../event/utilities/is-paid-for-member';

/**
 * When selecting a look, provide the selected look and current event members array.
 * Returns a new array of event members with the selected look toggled on or off for each member.
 * If a member is paid, their look will not be changed.
 * If a member already has the selected look, it will be removed (toggled off).
 * If a member does not have the selected look, it will be added (toggled on).
 * This does not mutate the original members array, but instead returns a new array.
 */
export function getNewMembersArrayWithSelectedLook(selectedLook: Look, members: EventMember[]): EventMember[] {
  console.log("getting new members array with selectedLook, with look id: ", selectedLook.id);
  const newEventMembers = members.map((member) => {
    // can't change look if member has paid
    if (isPaidForMember(member)) {
      console.log(`Member with id ${member?.id} is paid, cannot change look.`)
      return member;
    };

    // if member already has the selected look, remove it (toggle off)
    if (member.memberLook?.id === selectedLook.id) {
      console.log(`Member with id ${member?.id} already has the selected look, removing it.`);
      const { memberLook, ...memberWithoutLook } = member; // remove the memberLook property
      return memberWithoutLook;
    } else { // assign the selected look to the member
      console.log(`Member with id ${member?.id} does not have the selected look, adding it.`);
      const memberWithLook = { ...member, memberLook: selectedLook };
      return memberWithLook;
    }
  });

  return newEventMembers;
}
