import { DecodedUser, Event } from "common-types";

/**
 * Determines if a decoded user is a member of an event.
 * Checks if the decodedUser's uid or email matches any of the event's members.
 * @param event the event to check
 * @param decodedUser the user to check
 * @returns true if the user is a member of the event, false otherwise
 */
export function isDecodedUserEventMember(event: Event, decodedUser: DecodedUser) {
  if (!event || !decodedUser) {
    return false;
  }
  if (event.members) {
    return event.members.some((member) => {
      const userIdMatch = member.userId === decodedUser.uid;
      const emailMatch = member.email === decodedUser.email;
      return userIdMatch || emailMatch;
    });
  }
  return false;
}
