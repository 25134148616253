export function getLegacyShoeSize(shoeSize: string): [string, string] {
  let shoeSizeArray = shoeSize.split(' ');
  let legacyShoeSize = '';
  let legacyShoeWidth = '';
  if (shoeSizeArray.length === 1) {
    // a medium shoe.
    legacyShoeWidth = 'M';
    if (shoeSizeArray[0].length === 1) {
      // pad both sides with 0. like a 8 Medium
      legacyShoeSize = '0' + shoeSizeArray[0] + '0';
    } else {
      // two digit. just pad right.
      legacyShoeSize = shoeSizeArray[0] + '0';
    }
  } else if (shoeSizeArray.length === 2) {
    // can be a medium half shoe or wide/boy
    if (shoeSizeArray[1] === '1/2') {
      // half size medium
      legacyShoeWidth = 'M';
      if (shoeSizeArray[0].length === 1) {
        // pad left with 0 and right with 5. like a 8 1/2
        legacyShoeSize = '0' + shoeSizeArray[0] + '5';
      } else {
        // two digit. just pad right.
        legacyShoeSize = shoeSizeArray[0] + '5';
      }
    } else {
      // not half, second array element is specifying width.
      if (shoeSizeArray[0].length === 1) {
        // pad both sides with 0. like a 8 Medium
        legacyShoeSize = '0' + shoeSizeArray[0] + '0';
      } else {
        // two digit. just pad right.
        legacyShoeSize = shoeSizeArray[0] + '0';
      }
      if (shoeSizeArray[1] === 'Wide') {
        legacyShoeWidth = 'W';
      } else {
        legacyShoeWidth = 'B';
      }
    }
  } else {
    // 3 elements. half size with wide/boys.
    if (shoeSizeArray[0].length === 1) {
      // pad left with 0 and right with 5. like a 8 1/2
      legacyShoeSize = '0' + shoeSizeArray[0] + '5';
    } else {
      // two digit. just pad right.
      legacyShoeSize = shoeSizeArray[0] + '5';
    }
    if (shoeSizeArray[2] === 'Wide') {
      legacyShoeWidth = 'W';
    } else {
      legacyShoeWidth = 'B';
    }
  }
  return [legacyShoeSize, legacyShoeWidth];
}
