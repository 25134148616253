/**
 * Used to identify the site the user is currently on. e.g. 'Dealer Portal', 'Customer Service Portal', etc.
 * This should be set as an environment variable in the environment.ts file for every app.
 */
export const Site = {
  AccountingPortal: 'Accounting Portal',
  CustomerService: 'Customer Service Portal',
  DealerPortal: 'Dealer Portal',
  Ecom: 'Ecommerce',
  ITAdmin: 'IT Admin Portal',
  ITEcomAdmin: 'IT Ecom Admin Portal',
  Merchandising: 'Merchandising Portal',
  Sales: 'Sales Portal',
  EOO2: 'Essentials On Order Portal',
  CorporateLeadership: 'Corporate Leadership Portal',
  Marketing: 'Marketing Portal',
  ProductionManagement: 'Production Management Portal',
  RegionalManager: 'Regional Manager Portal',
  POC: 'POC Portal',
} as const;

/**
 * Used to identify the site the user is currently on. e.g. 'Dealer Portal', 'Customer Service Portal', etc.
 * This should be set as an environment variable in the environment.ts file for every app.
 */
export type Site = (typeof Site)[keyof typeof Site];

export function isValidSite(site: any): site is Site {
  return Object.values(Site).includes(site);
}
