import { z } from 'zod';
import { EventSchema } from './event.type';

export const EventDetailsSchema = EventSchema
  .pick({
    eventName: true,
    eventType: true,
    eventDate: true,
  }).partial()

export type EventDetails = z.infer<typeof EventDetailsSchema>;
