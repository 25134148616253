import {
  CanBeValidated,
  EventAdmin as EventAdminInterface,
  Serializable,
} from 'common-types';
import { ZodError, ZodSchema } from 'zod';
import { EventAdminValidator } from './validation/event-admin-validator';

export class EventAdmin
  implements
    Partial<EventAdminInterface>,
    Serializable<EventAdmin>,
    CanBeValidated
{
  id?: string | undefined;
  name?: string | undefined;
  role?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;

  toObject() {
    return {
      id: this.id,
      name: this.name,
      role: this.role,
      email: this.email,
      phone: this.phone,
    };
  }

  serialize(): string {
    return JSON.stringify(this.toObject());
  }

  deserialize(input: Partial<EventAdminInterface>): EventAdmin {
    this.id = input.id;
    this.name = input.name;
    this.role = input.role;
    this.email = input.email;
    this.phone = input.phone;
    return this;
  }

  isValid(validationSchema?: ZodSchema): boolean {
    if (validationSchema === undefined) {
      validationSchema = EventAdminValidator;
    }

    try {
      validationSchema.parse(this);
      return true;
    } catch (err) {
      if (err instanceof ZodError) {
        return false;
      } else {
        throw err;
      }
    }
  }

  getErrors(validationSchema?: ZodSchema): ZodError<any> | undefined {
    if (validationSchema === undefined) {
      validationSchema = EventAdminValidator;
    }

    try {
      validationSchema.parse(this);
    } catch (err) {
      if (err instanceof ZodError) {
        return err;
      } else {
        throw err;
      }
    }

    return undefined;
  }
}
