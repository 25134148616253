import {
  Category,
  Color,
  ColorFamily,
  EcomStyle,
  FilterCategory,
  MatchingOrRecommendedStyle,
  StyleType,
} from 'common-types';
import { getFilterCategoriesByStyleType } from './get-filter-categories-by-style-type';
type EcomStyleWithMatchingOrRecommended = {
  ecomStyle: EcomStyle;
  matchingOrRecommended?: MatchingOrRecommendedStyle;
};
export function getFilterOptionsByStyleType(
  styles: Array<EcomStyleWithMatchingOrRecommended>,
  styleType: StyleType
): Array<FilterCategory> {
  const filterCategoryOptions: Array<FilterCategory> = [];
  styles.forEach((style) => {
    const filterCategories: Array<FilterCategory> =
      getFilterCategoriesByStyleType(styleType);
    filterCategories.forEach((category) => {
      if (style.ecomStyle && category.attribute in style.ecomStyle) {
        // category exists on style (ie. color)
        const i = filterCategoryOptions.findIndex((cat) => {
          return cat.attribute === category.attribute;
        });
        if (i > -1) {
          // filter category already exists.
          let iOption = filterCategoryOptions[i].options?.findIndex((op) => {
            return (
              op.value ===
              (style.ecomStyle[
                category.attribute as keyof typeof style.ecomStyle
              ] as string)
            );
          });

          if (iOption === -1) {
            // FilterOption isn't there. Add it to the Category.
            filterCategoryOptions[i].options?.push({
              value: style.ecomStyle[
                category.attribute as keyof typeof style.ecomStyle
              ] as string,
            });
          }
        } else {
          // doesn't exist.
          filterCategoryOptions.push({
            attribute: category.attribute,
            displayName: category.displayName,
            select: category.select,
            options: [
              {
                value: style.ecomStyle[
                  category.attribute as keyof typeof style.ecomStyle
                ] as string,
              },
            ],
          });
        }
      } else if (
        style.matchingOrRecommended &&
        category.attribute in style.matchingOrRecommended
      ) {
        const i = filterCategoryOptions.findIndex((cat) => {
          return cat.attribute === category.attribute;
        });
        if (i > -1) {
          // filter category already exists.
          let iOption = filterCategoryOptions[i].options?.findIndex((op) => {
            if (style.matchingOrRecommended) {
              return (
                op.value ===
                (style.matchingOrRecommended[
                  category.attribute as keyof typeof style.matchingOrRecommended
                ] as string)
              );
            }
            return;
          });

          if (iOption === -1) {
            // FilterOption isn't there. Add it to the Category.
            filterCategoryOptions[i].options?.push({
              value: style.matchingOrRecommended[
                category.attribute as keyof typeof style.matchingOrRecommended
              ] as string,
            });
          }
        } else {
          // doesn't exist.
          filterCategoryOptions.push({
            attribute: category.attribute,
            displayName: category.displayName,
            select: category.select,
            options: [
              {
                value: style.matchingOrRecommended[
                  category.attribute as keyof typeof style.matchingOrRecommended
                ] as string,
              },
            ],
          });
        }
      } else {
        return;
      }
    });
  });
  const colorSortOrder: String[] = [
    'Black',
    'Grey',
    'Grey/Silver',
    'White',
    'White/Ivory',
    'Yellow/Gold',
    'Tan/Brown',
    'Coral/Orange',
    'Pink',
    'Red',
    'Red/Burgundy',
    'Purple',
    'Blue',
    'Blue/Navy',
    'Turquoise/Teal',
    'Green',
  ];

  filterCategoryOptions.map((filterOption) => {
    if (filterOption.attribute === 'colorFamily') {
      return (filterOption.options = filterOption.options?.sort((a, b) => {
        return (
          colorSortOrder.indexOf(a.value) - colorSortOrder.indexOf(b.value)
        );
      }));
    } else {
      return;
    }
  });

  return filterCategoryOptions;
}
