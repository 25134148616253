import { AccountCommissionGrouping } from 'common-types';

export function getReferralOnlyCommissions(
  accountCommissionGrouping: AccountCommissionGrouping[]
): AccountCommissionGrouping[] {
  accountCommissionGrouping.forEach((group) => {
    group.commissions = group.commissions.filter(
      (commission) =>
        commission.commissionType.toString().toUpperCase() === 'REFERRAL'
    );
  });

  return accountCommissionGrouping;
}
