import {
  LegacyOrder,
  LegacyOrderAccount,
  LegacyOrderControl,
  LegacyOrderDetail,
  LegacyOrderHeader,
  Order,
  OrderDetail,
} from 'common-types';
import { getLegacyHalfSize, getLegacyHeight } from '../../measurements';

export function convertOrderToLegacyOrder(order: Order): LegacyOrder {
  const legacyOrderAccount: LegacyOrderAccount = {
    live: true,
    csrmode: true,
  };
  // console.log('order.commissionAccountNumber:', order.commissionAccountNumber);
  const legacyOrderHeader: LegacyOrderHeader = {
    trntyp: 'NEWORDER',
    strtick: '',
    actnum: order.accountNumber,
    pweek: order.productionWeek,
    ordnum: 0,
    usedat: order.useDate,
    stractn: order.shipToAccount,
    stname: order.shipToName,
    stadr1: order.shipToAddress1,
    stadr2: order.shipToAddress2,
    stcity: order.shipToCity,
    ststat: order.shipToState,
    stzip: order.shipToZipcode,
    wkout: order.weeksOut,
    ordtyp: order.orderType,
    callerfn: order.callerFirstName,
    callerln: order.callerLastName,
    otext: order.orderText,
    ch:
      order.customer.measurements.chest === undefined
        ? ''
        : Math.round(order.customer.measurements.chest),
    oa:
      order.customer.measurements.overArm === undefined
        ? ''
        : Math.round(order.customer.measurements.overArm),
    ht:
      order.customer.measurements.height === undefined
        ? ''
        : getLegacyHeight(order.customer.measurements.height),
    wt:
      order.customer.measurements.weight === undefined
        ? ''
        : Math.round(order.customer.measurements.weight),
    sl:
      order.customer.measurements.sleeve === undefined
        ? ''
        : Math.round(order.customer.measurements.sleeve),
    nk:
      order.customer.measurements.neck === undefined ||
        order.customer.measurements.neck === null
        ? ''
        : getLegacyHalfSize(order.customer.measurements.neck),
    is:
      order.customer.measurements.inseam === undefined ||
        order.customer.measurements.inseam === null
        ? ''
        : getLegacyHalfSize(order.customer.measurements.inseam),
    os:
      order.customer.measurements.outseam === undefined ||
        order.customer.measurements.outseam === null
        ? ''
        : getLegacyHalfSize(order.customer.measurements.outseam),
    sh:
      order.customer.measurements.shoeSize === undefined ||
        order.customer.measurements.shoeSize === null
        ? ''
        : getLegacyHalfSize(order.customer.measurements.shoeSize),
    sw:
      order.customer.measurements.shoeWidth === undefined
        ? ''
        : order.customer.measurements.shoeWidth,
    st:
      order.customer.measurements.stomach === undefined ||
        order.customer.measurements.stomach === null
        ? ''
        : Math.round(order.customer.measurements.stomach),
    ws:
      order.customer.measurements.waist === undefined
        ? ''
        : Math.round(order.customer.measurements.waist),
    c1:
      order.customer.measurements.coatSize === undefined
        ? ''
        : order.customer.measurements.coatSize,
    vs:
      order.customer.measurements.vestSize === undefined
        ? ''
        : order.customer.measurements.vestSize,
    rs:
      order.customer.measurements.pantRise === undefined
        ? ''
        : order.customer.measurements.pantRise,
    hp:
      order.customer.measurements.hip === undefined
        ? ''
        : Math.round(order.customer.measurements.hip),
    intnum: order.internalOrderNumber,
    pudate: order.pickupDate,
    hotshot: order.hotShot,
    natevid: order.nationalEventId,
    evntds: order.eventDescription,
    crole: order.customer.role,
    cfname: order.customer.firstName,
    clname: order.customer.lastName,
    caddr1: order.customer.address1,
    caddr2: order.customer.address2 ?? '',
    ccity: order.customer.city,
    cstate: order.customer.state,
    czip: order.customer.zipCode,
    ccellph: order.customer.cellPhone,
    chomeph: order.customer.homePhone ?? '',
    cemail: order.customer.email,
    cage: order.customer.age,
    csex: order.customer.sex ?? '',
    potord: order.potentialOrder,
    comacct: order.commissionAccountNumber,
    wbevid: order.webEventId,
    wbmbid: order.webMemberId,
    chgusr: order.changeUser,
    whse: order.warehouse,
  };
  const legacyOrderControl: LegacyOrderControl = {
    documentId: order.documentId,
    eventId: order.eventId,
    cartId: order.cartId,
    memberId: order.memberId,
  };
  let legacyOrderDetails: Array<LegacyOrderDetail> = [];
  order.details.forEach((orderDetail: OrderDetail) => {
    if (orderDetail.line <= 25) {
      const legacyOrderDetail: LegacyOrderDetail = {
        txline: orderDetail.transactionLine,
        line: orderDetail.line,
        lstat: orderDetail.lineStatus ?? '',
        prodid: orderDetail.productId,
        style: orderDetail.style,
        opt1: orderDetail.option1,
        opt2: orderDetail.option2,
        opt3: orderDetail.option3 ?? '',
        qtyord: orderDetail.quantityOrdered,
        repl: orderDetail.replacementCode ?? '',
        replline: orderDetail.replacementLine ?? 0,
        qualitydesc: orderDetail.qualityDescription ?? '',
        whse: orderDetail.warehouse,
        carr: orderDetail.carrier ?? '',
        carsrv: orderDetail.carrierService ?? '',
        ashpdat: '0001-01-01', // orderDetail.adjustedShipDate,
        dtext: orderDetail.detailText ?? '',
      };
      if (orderDetail.chargeCode !== undefined) {
        legacyOrderDetail.chrgcd = orderDetail.chargeCode;
      }
      if (orderDetail.trackingNumber !== undefined) {
        legacyOrderDetail.trknum = orderDetail.trackingNumber;
      }
      legacyOrderDetails.push(legacyOrderDetail);
    }
  });
  const legacyOrder: LegacyOrder = {
    account: legacyOrderAccount,
    header: legacyOrderHeader,
    detail: legacyOrderDetails,
    control: legacyOrderControl,
    _xmlns: 'https://bbl.jimsfw.com/Order',
  };
  // console.log('legacyOrder:', legacyOrder);
  return legacyOrder;
}
