import { Cart, MemberCart } from 'common-types';

export function getMemberIdsFromCart(cart: Cart): Array<string> {
  let memberIds: Array<string> = [];
  if (cart.members !== undefined && cart.members.length > 0) {
    cart.members.forEach((memberCart: MemberCart) => {
      memberIds.push(memberCart.id);
    });
  }
  return memberIds;
}
