export const EventV5Message = {
  DELETE_MEMBER_TRANSACTION_ERROR: 'Failed transaction for deleting a member from an event',
  DELETE_MEMBER_MISMATCH: 'Number of members to remove does not match number of memberIds to remove',
  UPDATE_MEMBER_DETAILS: 'Failed transaction for updating a member details',
  ADD_MEMBER_TO_EVENT: 'Failed transaction for adding a member to an event',
  ADD_MEMBER_MISMATCH: 'Number of members added does not match number of memberIds added',
  CREATE_EVENT_WITH_USER_EVENTS: 'Failed transaction for creating an Event with DealerUserEvent or UserEvent',
  SELECT_LOOK: 'Failed transaction for selecting a look',
} as const;

export type EventV5Message = typeof EventV5Message[keyof typeof EventV5Message];
