import * as z from 'zod';

import { JFWNewEmailSchema } from '../email';
import { JFWConfirmPasswordSchema, JFWNewPasswordSchema } from '../password';
import { PhoneObjectSchema, PhoneStringSchema } from '../phone';
import { isPasswordMatch, passwordMatchRefineParams } from '../password/password-match.type';

export const CreateAccountFormSchema = z.object({
  firstName: z.string().trim().min(1, "First name is required"),
  lastName: z.string().trim().min(1, "Last name is required"),
  email: JFWNewEmailSchema,
  newPassword: JFWNewPasswordSchema,
  confirmNewPassword: JFWConfirmPasswordSchema,
  phone: PhoneObjectSchema.optional()
}).refine(isPasswordMatch, passwordMatchRefineParams);

export type CreateAccountForm = z.infer<typeof CreateAccountFormSchema>;
