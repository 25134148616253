export function rolesToPermissions(role: string): string[] {
  let permissions: string[] = [];
  switch (role) {
    case 'Customer Service - base':
      permissions = ['/create-user', '/pending-users'];
      return permissions;
    case 'Customer Service - ecommerce':
      permissions = [
        '/dealer-users',
        '/create-dealer-user',
        '/pending-dealer-users',
      ];
      return permissions;
    default:
      return permissions;
  }
}

export function hasPermissionToAPI(apiName: string, role: string) {
  switch (apiName) {
    case 'v2-platform-user':
      if (role == 'Customer Service - base') {
        console.log('User has permission to use this endpoint');
      } else {
        console.log('User does not have permission to use this endpoint');
      }
      break;
    case 'v1-dealer-user':
      if (role == 'Customer Service - ecommerve') {
        console.log('User has permission to use this endpoint');
      } else {
        console.log('User does not have permission to use this endpoint');
      }
      break;
    case 'v1-account':
      if (role == 'Customer Service - ecommerce') {
        console.log('User has permission to use this endpoint');
      } else {
        console.log('User does not have permission to use this endpoint');
      }
      break;
  }
}
