import { AppCheckErrorCode } from "../middleware";
import { AuthMiddlewareUnauthorized } from "../middleware/auth-middleware-response.type";

export const SendEmailVerificationErrorCode = {
  // userNotFound: 'User not found',
  emailAlreadyVerified: 'Email is already verified',
  noEmail: 'Email is required',
  firestoreError: 'Firestore Error',
  unknown: 'Unknown Error',
} as const;

export type SendEmailVerificationErrorCode = typeof SendEmailVerificationErrorCode[keyof typeof SendEmailVerificationErrorCode];


type SendEmailVerificationResponseSuccess = {
  success: true;
  docId: string;
}

type SendEmailVerificationResponseError = {
  success: false;
  error: SendEmailVerificationErrorCode;
} | AuthMiddlewareUnauthorized | AppCheckErrorCode;

export type SendEmailVerificationResponse = SendEmailVerificationResponseSuccess | SendEmailVerificationResponseError;
