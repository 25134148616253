import { CommissionDetail, OrderDetail } from 'common-types';

export function getPurchaseCommission(
  orderDetail: OrderDetail
): CommissionDetail {
  const { replacementCode } = orderDetail;
  if (replacementCode === undefined || replacementCode === '') {
    // Purchase
    return {
      styleGroup: orderDetail.styleGroup,
      price: orderDetail.price ?? 0,
      replacement: false,
      legacyStyleCode: orderDetail.style,
      styleType: orderDetail.productId,
      lineStatus: orderDetail.lineStatus,
      commission:
        orderDetail.lineStatus === 'D'
          ? 0.0
          : parseFloat(((orderDetail.price ?? 0) * 0.1).toFixed(2)),
      warehouse: orderDetail.warehouse,
      avalaraLineId: orderDetail.avalaraLineId ?? '',
    };
  } else {
    // Replacement
    return {
      styleGroup: orderDetail.styleGroup,
      price: 0,
      replacement: true,
      legacyStyleCode: orderDetail.style,
      styleType: orderDetail.productId,
      lineStatus: orderDetail.lineStatus,
      commission: 0.0,
      warehouse: orderDetail.warehouse,
      avalaraLineId: orderDetail.avalaraLineId ?? '',
    };
  }
}
