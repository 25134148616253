import { Order, PromoCode, PromoDetail } from 'common-types';

export function isOrderRentalCommissionExempt(order: Order): boolean {
  let exempt: boolean = false;
  if (order.promoDetails !== undefined) {
    order.promoDetails.forEach((detail: PromoDetail) => {
      if (
        // Grooms Free or Quince Free is set and not rejected. Order's rental commission is exempt.
        (detail.code === PromoCode.GROOMSFREE ||
          detail.code === PromoCode.QUINCEFREE) &&
        detail.rejectedAt === undefined
      ) {
        exempt = true;
      }
    });
  }
  return exempt;
}
