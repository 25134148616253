import { Event } from 'common-types';

export function getUnlockDate(event: Event): number {
  if (event.unlockDate !== undefined) {
    return event.unlockDate;
  } else {
    const eventDate = new Date(event.eventDate * 1000);
    // 13 days prior to event.
    const dateOffset = 24 * 60 * 60 * 1000 * 13;
    eventDate.setTime(eventDate.getTime() - dateOffset);
    return eventDate.getTime() / 1000;
  }
}
