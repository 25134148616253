import { FirebaseErrorCode } from '../../firebase-errors/firebase-error-code.type';

export const UpdateDealerCommunicationPrefsErrorCode = {
  CLIENT: 'CLIENT',
  FIRESTORE: 'FIRESTORE',
  TRANSACTION: 'TRANSACTION',
  UNKNOWN: 'UNKNOWN',
} as const;

export type UpdateDealerCommunicationPrefsErrorCode =
  keyof typeof UpdateDealerCommunicationPrefsErrorCode;

export interface UpdateDealerCommunicationPrefsError {
  code: UpdateDealerCommunicationPrefsErrorCode | FirebaseErrorCode;
  message: string;
  stack?: string;
}
