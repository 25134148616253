export const AuthErrorCode = {
  auth_invalid_login_credentials: 'auth/invalid-login-credentials', // this was added when enabling "Email Enumeration Protection" in Firebase Console
  // 'auth/admin-restricted-operation': 'auth/admin-restricted-operation',
  auth_argument_error: 'auth/argument-error',
  auth_app_not_authorized: 'auth/app-not-authorized',
  auth_app_not_installed: 'auth/app-not-installed',
  auth_captcha_check_failed: 'auth/captcha-check-failed',
  auth_code_expired: 'auth/code-expired',
  // 'auth/cordova_not_ready: 'auth/cordova-not-ready',
  auth_cors_unsupported: 'auth/cors-unsupported',
  auth_credential_already_in_use: 'auth/credential-already-in-use',
  auth_custom_token_mismatch: 'auth/custom-token-mismatch',
  auth_requires_recent_login: 'auth/requires-recent-login',
  // 'auth/dependent_sdk_initialized_before_auth: 'auth/dependent-sdk-initialized-before-auth',
  // 'auth/dynamic_link_not_activated: 'auth/dynamic-link-not-activated',
  auth_email_change_needs_verification: 'auth/email-change-needs-verification',
  auth_email_already_in_use: 'auth/email-already-in-use',
  auth_invalid_email: 'auth/invalid-email',
  auth_invalid_emulator_scheme: 'auth/invalid-emulator-scheme',
  auth_invalid_credential: 'auth/invalid-credential',
  auth_invalid_message_payload: 'auth/invalid-message-payload',
  // 'auth/invalid_multi_factor_session: 'auth/invalid-multi-factor-session',
  // 'auth/invalid_oauth_client_id: 'auth/invalid-oauth-client-id',
  // 'auth/invalid_oauth_provider: 'auth/invalid-oauth-provider',
  auth_invalid_action_code: 'auth/invalid-action-code',
  auth_unauthorized_domain: 'auth/unauthorized-domain',
  auth_wrong_password: 'auth/wrong-password',
  auth_invalid_persistence_type: 'auth/invalid-persistence-type',
  auth_invalid_phone_number: 'auth/invalid-phone-number',
  auth_invalid_provider_id: 'auth/invalid-provider-id',
  auth_invalid_recipient_email: 'auth/invalid-recipient-email',
  auth_invalid_sender: 'auth/invalid-sender',
  auth_invalid_verification_id: 'auth/invalid-verification-id',
  auth_invalid_tenant_id: 'auth/invalid-tenant-id',
  // 'auth/multi-factor-info-not-found: 'auth/multi-factor-info-not-found',
  // 'auth/multi-factor-auth-required: 'auth/multi-factor-auth-required',
  // 'auth/missing-android-pkg-name: 'auth/missing-android-pkg-name',
  auth_missing_app_credential: 'auth/missing-app-credential',
  // 'auth/auth_domain_config_required: 'auth/auth-domain-config-required',
  auth_missing_verification_code: 'auth/missing-verification-code',
  // 'auth/missing_continue_uri: 'auth/missing-continue-uri',
  // 'auth/missing_iframe_start: 'auth/missing-iframe-start',
  // 'auth/missing_ios_bundle_id: 'auth/missing-ios-bundle-id',
  auth_missing_or_invalid_nonce: 'auth/missing-or-invalid-nonce',
  // 'auth/missing_multi_factor_info: 'auth/missing-multi-factor-info',
  // 'auth/missing_multi_factor_session: 'auth/missing-multi-factor-session',
  auth_missing_phone_number: 'auth/missing-phone-number',
  auth_missing_verification_id: 'auth/missing-verification-id',
  auth_app_deleted: 'auth/app-deleted',
  auth_account_exists_with_different_credential: 'auth/account-exists-with-different-credential',
  auth_network_request_failed: 'auth/network-request-failed',
  auth_null_user: 'auth/null-user',
  auth_no_auth_event: 'auth/no-auth-event',
  auth_no_such_provider: 'auth/no-such-provider',
  auth_operation_not_allowed: 'auth/operation-not-allowed',
  auth_operation_not_supported_in_this_environment: 'auth/operation-not-supported-in-this-environment',
  auth_popup_blocked: 'auth/popup-blocked',
  auth_provider_already_linked: 'auth/provider-already-linked',
  auth_quota_exceeded: 'auth/quota-exceeded',
  auth_redirect_cancelled_by_user: 'auth/redirect-cancelled-by-user',
  auth_redirect_operation_pending: 'auth/redirect-operation-pending',
  auth_rejected_credential: 'auth/rejected-credential',
  // 'auth/second-factor-already-in-use: 'auth/second-factor-already-in-use',
  // 'auth/maximum-second-factor-count-exceeded: 'auth/maximum-second-factor-count-exceeded',
  // 'auth/tenant-id-mismatch: 'auth/tenant-id-mismatch',
  auth_timeout: 'auth/timeout',
  auth_user_token_expired: 'auth/user-token-expired',
  auth_too_many_requests: 'auth/too-many-requests',
  // 'auth/unauthorized_continue_uri: 'auth/unauthorized-continue-uri',
  // 'auth/unsupported_first_factor: 'auth/unsupported-first-factor',
  auth_unsupported_persistence_type: 'auth/unsupported-persistence-type',
  // 'auth/unsupported_tenant_operation: 'auth/unsupported-tenant-operation',
  auth_unverified_email: 'auth/unverified-email',
  auth_user_cancelled: 'auth/user-cancelled',
  auth_user_not_found: 'auth/user-not-found',
  auth_user_disabled: 'auth/user-disabled',
  auth_user_mismatch: 'auth/user-mismatch',
  auth_user_signed_out: 'auth/user-signed-out',
  auth_weak_password: 'auth/weak-password',
  auth_web_storage_unsupported: 'auth/web-storage-unsupported',
  auth_already_initialized: 'auth/already-initialized',
  auth_recaptcha_not_enabled: 'auth/recaptcha-not-enabled',
  auth_missing_recaptcha_token: 'auth/missing-recaptcha-token',
  auth_invalid_recaptcha_token: 'auth/invalid-recaptcha-token',
  auth_invalid_recaptcha_action: 'auth/invalid-recaptcha-action',
  auth_missing_client_type: 'auth/missing-client-type',
  auth_missing_recaptcha_version: 'auth/missing-recaptcha-version',
  auth_invalid_recaptcha_version: 'auth/invalid-recaptcha-version',
  auth_invalid_req_type: 'auth/invalid-req-type',
} as const;

export type AuthErrorCode = typeof AuthErrorCode[keyof typeof AuthErrorCode];

// export const AuthErrorCode = {
//   // 'auth/admin-restricted-operation': 'auth/admin-restricted-operation',
//   'auth/argument-error': 'auth/argument-error',
//   'auth/app-not-authorized': 'auth/app-not-authorized',
//   'auth/app-not-installed': 'auth/app-not-installed',
//   'auth/captcha-check-failed': 'auth/captcha-check-failed',
//   'auth/code-expired': 'auth/code-expired',
//   // 'auth/cordova-not-ready': 'auth/cordova-not-ready',
//   'auth/cors-unsupported': 'auth/cors-unsupported',
//   'auth/credential-already-in-use': 'auth/credential-already-in-use',
//   'auth/custom-token-mismatch': 'auth/custom-token-mismatch',
//   'auth/requires-recent-login': 'auth/requires-recent-login',
//   // 'auth/dependent-sdk-initialized-before-auth': 'auth/dependent-sdk-initialized-before-auth',
//   // 'auth/dynamic-link-not-activated': 'auth/dynamic-link-not-activated',
//   'auth/email-change-needs-verification': 'auth/email-change-needs-verification',
//   'auth/email-already-in-use': 'auth/email-already-in-use',
//   'auth/invalid-email': 'auth/invalid-email',
//   'auth/invalid-emulator-scheme': 'auth/invalid-emulator-scheme',
//   'auth/invalid-credential': 'auth/invalid-credential',
//   'auth/invalid-message-payload': 'auth/invalid-message-payload',
//   // 'auth/invalid-multi-factor-session': 'auth/invalid-multi-factor-session',
//   // 'auth/invalid-oauth-client-id': 'auth/invalid-oauth-client-id',
//   // 'auth/invalid-oauth-provider': 'auth/invalid-oauth-provider',
//   'auth/invalid-action-code': 'auth/invalid-action-code',
//   'auth/unauthorized-domain': 'auth/unauthorized-domain',
//   'auth/wrong-password': 'auth/wrong-password',
//   'auth/invalid-persistence-type': 'auth/invalid-persistence-type',
//   'auth/invalid-phone-number': 'auth/invalid-phone-number',
//   'auth/invalid-provider-id': 'auth/invalid-provider-id',
//   'auth/invalid-recipient-email': 'auth/invalid-recipient-email',
//   'auth/invalid-sender': 'auth/invalid-sender',
//   'auth/invalid-verification-id': 'auth/invalid-verification-id',
//   'auth/invalid-tenant-id': 'auth/invalid-tenant-id',
//   // 'auth/multi-factor-info-not-found': 'auth/multi-factor-info-not-found',
//   // 'auth/multi-factor-auth-required': 'auth/multi-factor-auth-required',
//   // 'auth/missing-android-pkg-name': 'auth/missing-android-pkg-name',
//   'auth/missing-app-credential': 'auth/missing-app-credential',
//   // 'auth/auth-domain-config-required': 'auth/auth-domain-config-required',
//   'auth/missing-verification-code': 'auth/missing-verification-code',
//   // 'auth/missing-continue-uri': 'auth/missing-continue-uri',
//   // 'auth/missing-iframe-start': 'auth/missing-iframe-start',
//   // 'auth/missing-ios-bundle-id': 'auth/missing-ios-bundle-id',
//   'auth/missing-or-invalid-nonce': 'auth/missing-or-invalid-nonce',
//   // 'auth/missing-multi-factor-info': 'auth/missing-multi-factor-info',
//   // 'auth/missing-multi-factor-session': 'auth/missing-multi-factor-session',
//   'auth/missing-phone-number': 'auth/missing-phone-number',
//   'auth/missing-verification-id': 'auth/missing-verification-id',
//   'auth/app-deleted': 'auth/app-deleted',
//   'auth/account-exists-with-different-credential': 'auth/account-exists-with-different-credential',
//   'auth/network-request-failed': 'auth/network-request-failed',
//   'auth/null-user': 'auth/null-user',
//   'auth/no-auth-event': 'auth/no-auth-event',
//   'auth/no-such-provider': 'auth/no-such-provider',
//   'auth/operation-not-allowed': 'auth/operation-not-allowed',
//   'auth/operation-not-supported-in-this-environment': 'auth/operation-not-supported-in-this-environment',
//   'auth/popup-blocked': 'auth/popup-blocked',
//   'auth/provider-already-linked': 'auth/provider-already-linked',
//   'auth/quota-exceeded': 'auth/quota-exceeded',
//   'auth/redirect-cancelled-by-user': 'auth/redirect-cancelled-by-user',
//   'auth/redirect-operation-pending': 'auth/redirect-operation-pending',
//   'auth/rejected-credential': 'auth/rejected-credential',
//   // 'auth/second-factor-already-in-use': 'auth/second-factor-already-in-use',
//   // 'auth/maximum-second-factor-count-exceeded': 'auth/maximum-second-factor-count-exceeded',
//   // 'auth/tenant-id-mismatch': 'auth/tenant-id-mismatch',
//   'auth/timeout': 'auth/timeout',
//   'auth/user-token-expired': 'auth/user-token-expired',
//   'auth/too-many-requests': 'auth/too-many-requests',
//   // 'auth/unauthorized-continue-uri': 'auth/unauthorized-continue-uri',
//   // 'auth/unsupported-first-factor': 'auth/unsupported-first-factor',
//   'auth/unsupported-persistence-type': 'auth/unsupported-persistence-type',
//   // 'auth/unsupported-tenant-operation': 'auth/unsupported-tenant-operation',
//   'auth/unverified-email': 'auth/unverified-email',
//   'auth/user-cancelled': 'auth/user-cancelled',
//   'auth/user-not-found': 'auth/user-not-found',
//   'auth/user-disabled': 'auth/user-disabled',
//   'auth/user-mismatch': 'auth/user-mismatch',
//   'auth/user-signed-out': 'auth/user-signed-out',
//   'auth/weak-password': 'auth/weak-password',
//   'auth/web-storage-unsupported': 'auth/web-storage-unsupported',
//   'auth/already-initialized': 'auth/already-initialized',
//   'auth/recaptcha-not-enabled': 'auth/recaptcha-not-enabled',
//   'auth/missing-recaptcha-token': 'auth/missing-recaptcha-token',
//   'auth/invalid-recaptcha-token': 'auth/invalid-recaptcha-token',
//   'auth/invalid-recaptcha-action': 'auth/invalid-recaptcha-action',
//   'auth/missing-client-type': 'auth/missing-client-type',
//   'auth/missing-recaptcha-version': 'auth/missing-recaptcha-version',
//   'auth/invalid-recaptcha-version': 'auth/invalid-recaptcha-version',
//   'auth/invalid-req-type': 'auth/invalid-req-type',
// } as const;

// export type AuthErrorCode = keyof typeof AuthErrorCode;
