import * as z from 'zod';

import { JFWNewEmailSchema } from '../email';
import { PhoneObjectSchema } from '../phone';

export const PersonalInfoSchema = z.object({
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
  email: JFWNewEmailSchema,
  phone: PhoneObjectSchema.optional(),
});

export type PersonalInfoForm = z.infer<typeof PersonalInfoSchema>;
