import { EcomStyle, StyleType } from 'common-types';

export function getPayingForPrice(
  ecomStyle: EcomStyle,
  memberStyles: EcomStyle[]
): number | undefined {
  let isEnsemble = false;
  let displayedPrice = ecomStyle.price;

  const ensembleIncludedItems = [
    StyleType.Pant,
    StyleType.Shirt,
    StyleType.Tie,
    StyleType.Vest,
    StyleType.Jewelry,
  ];
  const styleGroup = ecomStyle.styleGroup;
  if (memberStyles) {
    isEnsemble = memberStyles[0].userCartSelectedStyleGroup === 'Rental';
  }

  const styleIncludedInEnsemble = ensembleIncludedItems.includes(
    ecomStyle.styleType
  );

  if (
    (ecomStyle.styleType !== 'Jewelry' ||
      ecomStyle.collection !== 'Premium (PRM)') &&
    styleIncludedInEnsemble &&
    styleGroup === 'Rental' &&
    isEnsemble
  ) {
    displayedPrice = 0;
  }

  return displayedPrice;
}
