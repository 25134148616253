import { Event, EventMember } from 'common-types';

export function getQualifyingGroomsFreeMemberFromEvent(
  event: Event,
): EventMember | undefined {
  let i = event.members.findIndex((member: EventMember) => {
    return (
      (member.memberRole === 'Groom' ||
        member.memberRole === 'Honor Escort' ||
        member.memberRole === 'Bride') &&
      member.memberProgress?.paid !== undefined
    );
  });
  if (i === -1) {
    console.log(
      'unable to find qualifying member for grooms free promo. eventId is: ',
      event.id,
    );
    return undefined;
  } else {
    console.log(
      'found qualifying member for grooms free promo. member id is: ',
      event.members[i].id,
    );
    return event.members[i];
  }
}
