import { Event } from 'common-types';

export function getPurchasedLookIds(event: Event): Array<string | undefined> {
  const purchasedLooks: Array<string | undefined> = [];
  const { members } = event;
  if (members) {
    members.forEach((member) => {
      const memberPaid = member.memberProgress?.paid;
      if (memberPaid) {
        purchasedLooks.push(member.memberLook?.id);
      }
    });
  }
  return purchasedLooks;
}
