import * as z from 'zod';

export const MemberProgressSchema = z.object({
  inviteSent: z.number().optional(),
  inviteAccepted: z.number().optional(),
  measurements: z.number().optional(),
  paid: z.number().optional(),
  paidBy: z.string().optional(),
  shipped: z.number().optional(),
  returned: z.number().optional(),
});

export type ZodMemberProgress = z.infer<typeof MemberProgressSchema>;

export type MemberProgress = {
  inviteSent?: number;
  inviteAccepted?: number;
  measurements?: number;
  paid?: number;
  paidBy?: string;
  shipped?: number;
  returned?: number;
};
