import { Account } from './account';

export class AccountFactory {
  static loadAccountFromFirebase(accountJsonObject: string): Account {
    /*  Use method in API which has previously called DAL */
    let account = new Account();
    account.id = '1';
    account.name = 'Bettys Bridal';
    account.db2AccountAttributes = {
      comp: 2,
      actnum: 'AB123',
    };
    return account;
  }

  static loadAccountFromDb2(accountRecord: Array<string>): Account {
    /*  Use method in API which has previously called DAL */
    let account = new Account();
    account.db2AccountAttributes = {
      comp: 1,
      actnum: 'AB123',
    };
    return account;
  }
}
