import { Event } from 'common-types';

export function isEventSingleUser(event: Event): boolean {
  if (event.isSingleUser === true
    //&& event.inStoreInfo === undefined
  ) {
    return true;
  }
  return false;
}
