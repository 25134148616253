import { FirebaseErrorCode } from '../../firebase-errors/firebase-error-code.type';

export const UpdateEcomCommunicationPrefsErrorCode = {
  CLIENT: 'CLIENT',
  FIRESTORE: 'FIRESTORE',
  TRANSACTION: 'TRANSACTION',
  UNKNOWN: 'UNKNOWN',
} as const;

export type UpdateEcomCommunicationPrefsErrorCode =
  keyof typeof UpdateEcomCommunicationPrefsErrorCode;

export interface UpdateEcomCommunicationPrefsError {
  code: UpdateEcomCommunicationPrefsErrorCode | FirebaseErrorCode;
  message: string;
  stack?: string;
}
