import { AccountMaster, DealerAccountElastic } from 'common-types';

export const convertPlatFormAccountToElasticDocument = (
  platformAccount: AccountMaster
): DealerAccountElastic => {
  const elasticAccount: DealerAccountElastic = {
    actnum: platformAccount.accountNumber ?? '',
    address1: platformAccount.address?.streetAddress1 ?? '',
    address2: platformAccount.address?.streetAddress2 ?? '',
    aname: platformAccount.accountName ?? '',
    city: platformAccount.address?.city ?? '',
    email: platformAccount.email,

    //  Need to verify that this doesn't pull deleted 'D' accounts from AccountMaster
    isActive: platformAccount.accountStatus === 'A' ? true : false,
    website: platformAccount.accountWebSite ?? '',
    landingPageUrl: platformAccount.landingPageUrl ?? '',
    zipCode: platformAccount.address?.zip ?? '',
    location: {
      lat: platformAccount.storeLocationLatitude ?? 0,
      lon: platformAccount.storeLocationLongitude ?? 0,
    },

    // This is not in AccountMaster?
    // store_services

    contacts: platformAccount.contacts,

    phone: platformAccount.phone ?? '',
    properName: platformAccount.properName ?? '',
    offerings: platformAccount.offerings ?? [],
    state: platformAccount.address?.state ?? '',
    storeDescription: platformAccount.storeDescription ?? '',
    strtyp: platformAccount.storeType ?? '',
    warehouse: platformAccount.serviceCenter ?? '',

    mon_type: platformAccount.mon_type,
    mon_to: platformAccount.mon_to,
    mon_from: platformAccount.mon_from,

    tue_type: platformAccount.tue_type,
    tue_from: platformAccount.tue_from,
    tue_to: platformAccount.tue_to,

    wed_type: platformAccount.wed_type,
    wed_from: platformAccount.wed_from,
    wed_to: platformAccount.wed_to,

    thu_type: platformAccount.thu_type,
    thu_from: platformAccount.thu_from,
    thu_to: platformAccount.thu_to,

    fri_type: platformAccount.fri_type,
    fri_from: platformAccount.fri_from,
    fri_to: platformAccount.fri_to,

    sat_type: platformAccount.sat_type,
    sat_from: platformAccount.sat_from,
    sat_to: platformAccount.sat_to,

    sun_type: platformAccount.sun_type,
    sun_from: platformAccount.sun_from,
    sun_to: platformAccount.sun_to,
  };
  return elasticAccount;
};
