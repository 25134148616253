import { EcomStyle, StyleType } from 'common-types';

export function hasDisplayPrice(ecomStyle: EcomStyle): boolean {
  const stylesToHidePrice: StyleType[] = [
    StyleType.Vest,
    StyleType.Shirt,
    StyleType.Tie,
    StyleType.Pant,
    StyleType.Jewelry,
  ];
  if (
    ecomStyle.styleType === StyleType.Jewelry &&
    ecomStyle.collection === 'Premium (PRM)'
  ) {
    return false;
  }
  return stylesToHidePrice.includes(ecomStyle.styleType);
}
