import { Event } from 'common-types';
import { isUserOrganizerOrAdminForEvent } from '../../utilities';
import { memberInEvent } from './member-in-event';
import { validTimeElapsed } from './valid-time-elapsed';

export function canSendEmail(event: Event, memberEmail: string): boolean {
  if (validTimeElapsed()) {
    // If member list has the email
    if (memberInEvent(event, memberEmail)) {
      return true;
    } else if (isUserOrganizerOrAdminForEvent(event)) {
      return true;
    }
  }
  return false;
}
