import { getHolidays } from './get-holidays';

export function getBusinessDays(
  startDate: string | Date,
  endDate: string | Date
): number {
  let businessDays: number = 0;

  startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
  endDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

  let holidays = getHolidays(startDate, endDate);

  let currentDate = new Date(startDate.getTime());

  while (currentDate.getTime() <= endDate.getTime()) {
    let dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      businessDays++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  businessDays = businessDays - holidays.length;

  return businessDays;
}
