import { Event, EventAdmin } from 'common-types';

export function hasAdminConfirmedContactInfo(
  event: Event | undefined
): boolean {
  let lsUser = JSON.parse(localStorage.getItem('user')!);
  if (event === undefined || lsUser === undefined || lsUser === null) {
    return false;
  }

  let adminIndex = event.admins.findIndex((admin: EventAdmin) => {
    return (admin.id || admin.userId) === lsUser.uid;
  });

  if (adminIndex > -1) {
    return event.admins[adminIndex].confirmedInfo ?? false;
  }

  adminIndex = event.admins.findIndex((admin: EventAdmin) => {
    if (admin.email === undefined) {
      return -1;
    }
    return admin.email.toLowerCase() === lsUser.email.toLowerCase();
  });

  if (adminIndex > -1) {
    return event.admins[adminIndex].confirmedInfo ?? false;
  }

  return false;
}
