import { Event } from 'common-types';

export function updateMemberUserId(
  event: Event,
  userId: string,
  email: string
): Event {
  event.members.findIndex((member) => {
    return member.email == email;
  });

  let indexes = event.members.map((member) => member.email === email);

  let memberIndex = 0;
  indexes.forEach((indx) => {
    if (indx) {
      event.members[memberIndex].userId = userId;
    }
    memberIndex++;
  });

  return event;
}
