import { Event, EventAdmin } from 'common-types';

export function getOrganizerFromEvent(event: Event | undefined): EventAdmin | undefined {
  if (event === undefined) return undefined;
  let organizer: EventAdmin | undefined = event.admins.find(
    (admin: EventAdmin) => {
      return admin.isOrganizer === true;
    }
  );
  if (organizer !== undefined) {
    return organizer;
  } else {
    if (event.admins[0] !== undefined) {
      return event.admins[0];
    } else {
      return undefined;
    }
  }
}
