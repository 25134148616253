import { User } from 'firebase/auth';
import { EventAdmin } from '../../../event/event-admin.type';
import { Event } from '../../../event/event.type';
import { HttpMethod } from '../../../logger/http-method.enum';


// ********************************************************************************************************************
// *                                             COMMON RESPONSES                                                     *
// ********************************************************************************************************************
export const EventNotFoundResponse = "Event not found" as const;
export type EventNotFoundResponseType = typeof EventNotFoundResponse;
export const InvalidUserOrEventResponse = "invalid user/event" as const;
export type InvalidUserOrEventResponseType = typeof InvalidUserOrEventResponse;
export const InvalidDataResponse = "Invalid event data" as const;
export type InvalidDataResponseType = typeof InvalidDataResponse;
export const MissingMemberIdsResponse = "Missing memberIds" as const;
export type MissingMemberIdsResponseType = typeof MissingMemberIdsResponse;



// ********************************************************************************************************************
// *                                             CREATE                                                               *
// ********************************************************************************************************************

/// ************ POST /create ************ ///
export type CreateEventSuccessResponse = Event;
export const CreateEventMissingOrganizerResponse = "Missing organizer" as const;
export type CreateEventMissingOrganizerResponse = typeof CreateEventMissingOrganizerResponse;
export const CreateEventFailureResponse = "Something went wrong" as const;
export type CreateEventFailureResponse = typeof CreateEventFailureResponse;
export type CreateEventResponse = CreateEventSuccessResponse |
  CreateEventMissingOrganizerResponse |
  CreateEventFailureResponse |
  InvalidDataResponseType;

/**
 * Data to create a new event.
 * @param {CreateEventData} data
 */
export type CreateEventData = {
  /** the event to create */
  event: Event,
  /** Use the value of the environment.dealerPortal property.  This determines whether a UserEvent or DealerUserEvent is created. */
  dealerProcessing: boolean,
  /** the user from the AngularFireAuth service */
  user: User,
  /** the organizer of the event */
  organizer?: EventAdmin,
};



// ********************************************************************************************************************
// *                                             READ                                                                 *
// ********************************************************************************************************************

/// ************ GET /user/:id ************ ///
// export type GetAllEventsByUserIdRequest = NoBodyRequest;
// export type GetAllEventsByUserIdSuccessResponse = Event[];


/// ************ GET /:id ************ ///
export type GetEventByIdSuccessResponse = Event;
export type GetEventByIdResponse = GetEventByIdSuccessResponse | EventNotFoundResponseType | InvalidUserOrEventResponseType;


/// ************ GET /grooms-free/:eventId ************ ///
// export type GroomsFreeSuccessResponse = {
//   doesEventQualify: boolean;
//   qualifyingMember: EventMember | undefined;
// };


/// ************ POST /has-discontinued-styles/eventid/:id ************ ///
export type HasDiscontinuedStylesData = {
  memberIds: string[];
};
export type HasDiscontinuedStylesSuccessResponse = {
  id: string;
  membersHaveDiscontinuedStyles: boolean;
};

export type HasDiscontinuedStylesResponse = HasDiscontinuedStylesSuccessResponse |
  MissingMemberIdsResponseType |
  EventNotFoundResponseType |
  InvalidUserOrEventResponseType;



// ********************************************************************************************************************
// *                                             UPDATE                                                               *
// ********************************************************************************************************************

/// ************ POST /save ************ ///
export type SaveEventData = Event;

export type SaveEventUpdateSuccessResponse = Event & { errorOnUpdate: undefined };

/** Response when there is a mismatch between the update_time on the document provided in the request
 * and the updateTime from the metadata of the event fetched from the db on the backend */
export type SaveEventUpdateMismatchTimeResponse = Event & { errorOnUpdate: true };

export type SaveEventInsertSuccessResponse = Event;

export const SaveEventFailInsertResponse = "Failed to insert event" as const;
export type SaveEventFailInsertResponseType = typeof SaveEventFailInsertResponse;

export const SaveEventFailUpdateResponse = "Failed to update event" as const;
export type SaveEventFailUpdateResponseType = typeof SaveEventFailUpdateResponse;

/** The responses from the updateEvent function called by /save endpoint */
export type SaveEventUpdateResponse = SaveEventUpdateSuccessResponse |
  SaveEventUpdateMismatchTimeResponse |
  SaveEventFailUpdateResponseType |
  EventNotFoundResponseType |
  InvalidUserOrEventResponseType;
/** The responses from the insertEvent function called by /save endpoint */
export type SaveEventInsertResponse = SaveEventInsertSuccessResponse |
  SaveEventFailInsertResponseType
/** This is used only for the front end */
export type SaveEventSuccessResponse = SaveEventUpdateSuccessResponse | SaveEventInsertSuccessResponse;
/** This includes all possible responses for both insertEvent and updateEvent */
export type SaveEventResponse = SaveEventUpdateResponse | SaveEventInsertResponse | InvalidDataResponseType;



// ********************************************************************************************************************
// *                                             DELETE                                                               *
// ********************************************************************************************************************


/// ************ DELETE /delete/:id ************ ///
export type DeleteEventByIdSuccessResponse = true;
export const DeleteEventByIdFailureResponse = 'Failed to delete event' as const;
export type DeleteEventByIdFailureResponseType = typeof DeleteEventByIdFailureResponse;
export type DeleteEventByIdResponse = DeleteEventByIdSuccessResponse |
  DeleteEventByIdFailureResponseType |
  EventNotFoundResponseType |
  InvalidUserOrEventResponseType;



// Removing this endpoint because it is not currently used and is potentially dangerous for production.
/// ************ DELETE /deleteAllByUser/:userId ************ ///
// export type DeleteAllByUserRequest = NoBodyRequest;
// export type DeleteAllByUserSuccessResponse = void;







interface EndpointRoutesConfig {
  [key: string]: {
    route: string,
    httpMethod: HttpMethod,
  };
}

const endpointRoutesConfig = {
  createEvent: {
    route: 'create',
    httpMethod: HttpMethod.POST,
  },
  insertManyEvents: {
    route: 'insertManyEvents/:numCopies',
    httpMethod: HttpMethod.POST,
  },
  getEventById: {
    route: ':id',
    httpMethod: HttpMethod.GET,
  },
  hasDiscontinuedStyles: {
    route: 'has-discontinued-styles/eventid/:id',
    httpMethod: HttpMethod.POST,
  },
  saveEvent: {
    route: 'save',
    httpMethod: HttpMethod.POST,
  },
} as const satisfies EndpointRoutesConfig;

type ToEndpointRoutes<T> = T extends EndpointRoutesConfig ? T[keyof T]['route'] : never;
type EventV4EndpointRoutes = ToEndpointRoutes<typeof endpointRoutesConfig>;


const routeToParameterizedRouteFunction = (route: string) => {
  const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
  return {
    numArgs: routeArgs.length,
    toRoute: (...args: string[]) => {
      // if (routeArgs.length !== args.length) {
      //   throw new Error(`Expected ${routeArgs.length} arguments, but received ${args.length}`);
      // }
      return route.split('/').map((part) => {
        if (part.startsWith(':')) {
          return args.shift();
        }
        return part;
      }).join('/');
    }
  } as const;

}

const getNumArgs = <T extends EndpointRoutesConfig>(routesConfig: T) => {
  const keys: (keyof T)[] = Object.keys(routesConfig) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      const route = routesConfig[key].route
      const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
      const numArgs = routeArgs.length
      return {
        ...acc,
        [key]: numArgs,
      };
    }, {} as {
      [key in keyof T]: number
    }
    );
}

const getRoutes = <T extends EndpointRoutesConfig>(routesConfig: T) => {
  const keys: (keyof T)[] = Object.keys(routesConfig) as (keyof T)[]
  return keys
    .reduce((acc, key) => {
      const route = routesConfig[key].route
      const routeArgs = route.split('/').filter((part) => part.startsWith(':')).map((part) => part.slice(1));
      const numArgs = routeArgs.length
      return {
        ...acc,
        [key]: routeToParameterizedRouteFunction(route),
      };
    }, {} as {
      [key in keyof T]: {
        route: typeof routesConfig[key]['route'],
        numArgs: number,
        toRoute: (...args: any[]) => string
      }
    }
    );
};

export const eventV4Routes = getRoutes(endpointRoutesConfig);

const getEventByIdRoute = eventV4Routes.getEventById.route

