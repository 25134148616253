export const FirestoreErrorCode = {
  ABORTED: 'ABORTED',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  CANCELLED: 'CANCELLED',
  DATA_LOSS: 'DATA_LOSS',
  DEADLINE_EXCEEDED: 'DEADLINE_EXCEEDED',
  FAILED_PRECONDITION: 'FAILED_PRECONDITION',
  INTERNAL: 'INTERNAL',
  INVALID_ARGUMENT: 'INVALID_ARGUMENT',
  NOT_FOUND: 'NOT_FOUND',
  OK: 'OK',
  OUT_OF_RANGE: 'OUT_OF_RANGE',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  RESOURCE_EXHAUSTED: 'RESOURCE_EXHAUSTED',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  UNAVAILABLE: 'UNAVAILABLE',
  UNIMPLEMENTED: 'UNIMPLEMENTED',
  UNKNOWN: 'UNKNOWN',
} as const;

export type FirestoreErrorCode = keyof typeof FirestoreErrorCode;
