import { EcomStyle, EcomStyleSchema } from '../style/ecommerce/ecom-style.type';
import * as z from 'zod';

export const LookSchema = z.object({
  id: z.string(),
  title: z.string(),
  styles: z.array(EcomStyleSchema),
  price: z.number().optional(),
  ensembleCode: z.string().optional(),
  styleCodes: z.array(z.string()).optional(),
});

export type ZodLook = z.infer<typeof LookSchema>;

export interface Look {
  id: string; // unique ID for adding/editing/deleting within Event
  title: string; // entered by marketing for ensemble, customer for look
  styles: EcomStyle[]; // selected EcomStyles
  price?: number; // temporary, read only - not used for checkout
  ensembleCode?: string; // used for record keeping - jumping off point ensemble code
  styleCodes?: string[]; // used to keep track of styles in a Look/Ensemble (for firestore querying)
}
