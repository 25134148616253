import {
  Category,
  Color,
  ColorFamily,
  EcomStyle,
  FilterCategory,
} from 'common-types';
import { getFilterCategoriesByPlpCategory } from './get-filter-categories-by-plp-category';

export function getFilterOptions(
  styles: Array<EcomStyle>,
  plpCategory: Category
): Array<FilterCategory> {
  const filterCategoryOptions: Array<FilterCategory> = [];

  styles.forEach((style) => {
    const filterCategories: Array<FilterCategory> =
      getFilterCategoriesByPlpCategory(plpCategory);
    filterCategories.forEach((category) => {
      if (category.attribute in style) {
        // category exists on style (ie. color)
        const i = filterCategoryOptions.findIndex((cat) => {
          return cat.attribute === category.attribute;
        });

        if (i > -1) {
          // filter category already exists.
          let iOption = filterCategoryOptions[i].options?.findIndex((op) => {
            return (
              op.value ===
              (style[category.attribute as keyof typeof style] as string)
            );
          });

          if (iOption === -1) {
            // FilterOption isn't there. Add it to the Category.
            filterCategoryOptions[i].options?.push({
              value: style[category.attribute as keyof typeof style] as string,
            });
          }
        } else {
          // doesn't exist.
          filterCategoryOptions.push({
            attribute: category.attribute,
            displayName: category.displayName,
            select: category.select,
            options: [
              {
                value: style[
                  category.attribute as keyof typeof style
                ] as string,
              },
            ],
          });
        }
      }
    });
  });

  const colorSortOrder: String[] = [
    'Black',
    'Grey',
    'Grey/Silver',
    'White',
    'White/Ivory',
    'Yellow/Gold',
    'Tan/Chocolate',
    'Coral/Orange',
    'Pink',
    'Red',
    'Red/Burgundy',
    'Purple',
    'Blue',
    'Blue/Navy',
    'Turquoise/Teal',
    'Green',
  ];

  filterCategoryOptions.map((filterOption) => {
    if (filterOption.attribute === 'colorFamily') {
      return (filterOption.options = filterOption.options?.sort((a, b) => {
        return (
          colorSortOrder.indexOf(a.value) - colorSortOrder.indexOf(b.value)
        );
      }));
    } else {
      return;
    }
  });

  return filterCategoryOptions;
}
