import { getCurrentUnixTimestamp } from '../../../date';

import { Event, EventMember } from 'common-types';

export function markInviteAccepted(event: Event, memberEmail: string): Event {
  const date = getCurrentUnixTimestamp();

  event.members.forEach((member: EventMember) => {
    if (member.email === memberEmail) {
      if (member.memberProgress === undefined) {
        member.memberProgress = {};
      }
      member.memberProgress.inviteAccepted = date;
    }
  });
  return event;
}
