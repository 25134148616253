import { Measurements } from 'common-types';
import {
  getLegacyCoatSize,
  getLegacyHalfSize,
  getLegacyPantRise,
  getLegacyShoeSize,
} from '../../measurements';

export function getSizeFromMeasurements(
  legacyProductId: string,
  measurements: Measurements
): [string, string] {
  switch (legacyProductId) {
    case 'CT': // coatSize and coatLength
      if (measurements.coatSize !== undefined) {
        try {
          return getLegacyCoatSize(measurements.coatSize);
        } catch (e) {
          console.log(e);
          return ['', ''];
        }
      } else {
        // don't have coat size.
        return ['', ''];
      }
    case 'PT': // waist/rise and outseam
      if (
        measurements.waist !== undefined &&
        measurements.outseam !== undefined
      ) {
        try {
          let waist = Math.round(measurements.waist).toString();
          let pantRise = getLegacyPantRise(measurements);
          let outseam = getLegacyHalfSize(measurements.outseam).toString();
          return [waist + pantRise, outseam];
        } catch (e) {
          console.log(e);
          return ['', ''];
        }
      } else {
        return ['', ''];
      }
    case 'SR': // neck and sleeve
      if (
        measurements.neck !== undefined &&
        measurements.sleeve !== undefined
      ) {
        try {
          return [
            getLegacyHalfSize(measurements.neck),
            Math.round(measurements.sleeve).toString(),
          ];
        } catch (e) {
          console.log(e);
          return ['', ''];
        }
      } else {
        return ['', ''];
      }
    case 'SO': // size and width
      if (measurements.shoeSize !== undefined) {
        try {
          return getLegacyShoeSize(measurements.shoeSize);
        } catch (e) {
          console.log(e);
          return ['', ''];
        }
      } else {
        return ['', ''];
      }
    case 'HS': // socks
      if (measurements.shoeSize !== undefined) {
        // get sock size from shoe width.
        try {
          let [shoeSize, shoeWidth] = getLegacyShoeSize(measurements.shoeSize);
          if (shoeWidth === 'B') {
            return ['B', ''];
          } else {
            return ['M', ''];
          }
        } catch (e) {
          console.log(e);
          return ['', ''];
        }
      } else {
        return ['', ''];
      }
    case 'MS': // Belt
      if (measurements.waist !== undefined) {
        if (measurements.waist >= 21 && measurements.waist <= 24) {
          return ['XS', ''];
        } else if (measurements.waist >= 25 && measurements.waist <= 28) {
          return ['S', ''];
        } else if (measurements.waist >= 29 && measurements.waist <= 32) {
          return ['M', ''];
        } else if (measurements.waist >= 33 && measurements.waist <= 36) {
          return ['L', ''];
        } else if (measurements.waist >= 37 && measurements.waist <= 40) {
          return ['XL', ''];
        } else if (measurements.waist >= 41 && measurements.waist <= 44) {
          return ['2X', ''];
        } else if (measurements.waist >= 45 && measurements.waist <= 48) {
          return ['3X', ''];
        } else {
          // waist doesn't correspond to belt size.
          return ['', ''];
        }
      } else {
        return ['', ''];
      }
      case 'TI':
        if(measurements.neck !== undefined && measurements.neck < 13.0) {
          return ['B', ''];
        } else if(measurements.neck !== undefined && measurements.neck >= 13.0) {
          return ['M', ''];
        } else {
          return ['', ''];
        }
    default: // unknown.
      return ['', ''];
  }
}
