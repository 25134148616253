import { EventMember } from 'common-types';
import { getPayingForPrice } from './get-paying-for-price';

export function getMemberSubtotal(
  member: EventMember,
  includeUnselectedStyles: boolean = true
) {
  let subTotal = 0;
  // Coat is rent
  let isEnsemble = false;

  member.memberLook?.styles.forEach((ecomStyle) => {
    if (ecomStyle.styleType === 'Coat' && ecomStyle.styleGroup === 'Rental') {
      isEnsemble = true;
    }

    if (member.memberLook?.styles) {
      if (
        includeUnselectedStyles ||
        ecomStyle.userCartSelectedStyleGroup !== undefined
      ) {
        subTotal +=
          getPayingForPrice(ecomStyle, member.memberLook?.styles) ?? 0;
      }
    }
  });

  return subTotal;
}
