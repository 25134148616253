export const SendSuccessfulResetPasswordErrorCode = {
  email_missing: 'email-missing',
  invalid_email: 'invalid-email',
  tokensValidAfterTime_undefined: 'tokensValidAfterTime-undefined',
  tokensValidAfterTime_not_recent: 'tokensValidAfterTime-not-recent',
  user_not_found: 'user-not-found',
  user_disabled: 'user-disabled',
  too_many_requests: 'too-many-requests',
  unknown_error: 'unknown-error',
} as const;

export type SendSuccessfulResetPasswordErrorCode = typeof SendSuccessfulResetPasswordErrorCode[keyof typeof SendSuccessfulResetPasswordErrorCode];

type SendSuccessfulResetPasswordSuccess = {
  success: true;
  docId: string;
}

type SendSuccessfulResetPasswordFailure = {
  success: false;
  error: SendSuccessfulResetPasswordErrorCode;
}

export type SendSuccessfulResetPasswordResponse = SendSuccessfulResetPasswordSuccess | SendSuccessfulResetPasswordFailure;

